import PropTypes from 'prop-types';
import styles from './filterList.module.css';
import clsx from 'clsx';

export const FilterList = ({ dataItems, currentItem, choiceItem }) => {
	const renderItems = dataItems.map((item, index) => {
		const isCurrentItem = currentItem === index;

		return (
			<li
				onClick={() => choiceItem(index)}
				className={clsx(styles.item, isCurrentItem && styles.currentItem)}
				key={index}
			>
				{item.label}
			</li>
		);
	});

	return <ul className={styles.itemsList}>{renderItems}</ul>;
};

// FilterList.propTypes = {
// 	dataItems: PropTypes.arrayOf(PropTypes.string),
// 	choiceItem: PropTypes.func,
// 	currentItem: PropTypes.string,
// };
