export const getGroups = (zakazes) => {
	//Подсчет в map обьекте
	const setMapCount = (map, value) => {
		if (map.has(value)) {
			map.set(value, map.get(value) + 1);
		} else {
			map.set(value, 1);
		}
	};
	//для расчета по кол-ву деталей
	const splitStr = 'I';
	const getQuantityGroup = (value) => {
		if (!value) {
			return null;
		}

		const group = [
			[0, 1],
			[1, 5],
			[5, 20],
			[20, 50],
			[50, 100],
			[100, 1000],
			[1000, 10000],
		];
		const maxGroupValue = group[group.length - 1][1];
		let result = '';

		group.forEach((el, i) => {
			if (value > el[0] && value <= el[1]) {
				result = group[i].join(' - ');
				//Если в первой группе имеется 0
				if (i === 0 && group[0].includes(0)) {
					result = group[i][1];
				}
				//Добавим индекс для сортировки
				result += splitStr + i;
			}

			//Если значение не соответствовало никакой группе то результат '> [последнее число]'
			if (value > maxGroupValue) {
				result = '> ' + maxGroupValue + splitStr + (i + 1);
			}
		});

		return result;
	};

	//date
	const getDate = (item) => item.date.slice(0, 7);
	const monthMap = new Map();

	//works
	const getWorks = (item) => {
		if (item.work_info[0]) {
			return item.work_info.map((item) => item.name);
		}
		return null;
	};
	const worksMap = new Map();

	//cities
	const getCity = (item) => {
		return item.cities || null;
	};
	const citiesMap = new Map();

	//quantity
	const quantityMap = new Map();

	//------Loop------
	for (let i = 0; i < zakazes.length; i++) {
		const element = zakazes[i];
		//date
		const date = getDate(element);
		setMapCount(monthMap, date);

		//works
		const works = getWorks(element);
		if (works) {
			works.forEach((work) => {
				setMapCount(worksMap, work);
			});
		}

		//cities
		const city = getCity(element);
		if (city) {
			setMapCount(citiesMap, city);
		}

		//quantity
		const group = getQuantityGroup(element.kl);
		if (group) {
			setMapCount(quantityMap, group);
		}
	}

	const result = {
		dates: [],
		works: [],
		cities: [],
		quantity: [],
	};

	monthMap.forEach((val, key) => result.dates.push([key, val]));
	worksMap.forEach((val, key) => result.works.push([key, val]));
	citiesMap.forEach((val, key) => result.cities.push([key, val]));

	const dateSum = (date) => {
		const dateArr = date.split('-');
		return +dateArr[0] + dateArr[1];
	};
	result.dates.sort((a, b) => dateSum(b[0]) - dateSum(a[0]));
	result.works.sort((a, b) => b[1] - a[1]);
	result.cities.sort((a, b) => b[1] - a[1]);

	//в quantityMap key содержит порядковый номер после splitStr для сортировки
	quantityMap.forEach((val, key) => {
		const [newKey, order] = key.split(splitStr);
		result.quantity.push([newKey, { value: val, order }]);
	});
	result.quantity.sort((a, b) => a[1].order - b[1].order);
	//после сортировки приводим поле quantity к общему виду [[key, value]...]
	result.quantity = result.quantity.map((el) => [el[0], el[1].value]);

	return result;
};
