import React, { useState, useRef } from 'react';
import classes from './cropImageDialog.module.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ReactCrop from 'react-image-crop';
import {
	canvasPreview,
	useDebounceEffect,
	centerAspectCrop,
} from '../../utils/cropImage';
import 'react-image-crop/dist/ReactCrop.css';
import CropImageDialogPanel from './CropImageDialogPanel/CropImageDialogPanel';
import { getLoadingImageSize } from '../../utils/getLoadingImageSize';

const CropImageDialog = ({
	textDialog,
	open,
	handleCloseImage,
	// setNewImage,
	getCropImage,
	minHeight,
	...props
}) => {
	const [imgSrc, setImgSrc] = useState('');
	const previewCanvasRef = useRef(null);
	const imgRef = useRef(null);
	const [crop, setCrop] = useState();
	const [completedCrop, setCompletedCrop] = useState();
	const [scale, setScale] = useState(1);
	const [rotate, setRotate] = useState(0);
	const [aspect, setAspect] = useState(false);
	//Обрезанная картинка перед отправкой
	const [newImageCropped, setNewImageCropped] = useState();
	//Размеры загруженной или обрезанной картинки
	const [size, setSize] = useState(null);
	let errorText = '';
	if (minHeight && size && size.height < minHeight) {
		errorText = `Слишком маленькое фото. Минимальная высота ${minHeight} px.`;
	}
	//Выбор фото
	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined); // Makes crop preview update between images.
			setCompletedCrop(null); // Редактированная кропом картинка
			setNewImageCropped(null); // Сохраненая обрезанная картинка
			const reader = new FileReader();
			//Сетаем загруженную картинку и ее размеры
			reader.addEventListener('load', async () => {
				const image64 = reader.result.toString();
				setImgSrc(image64 || '');
				try {
					const imageSize = await getLoadingImageSize(image64);
					setSize(imageSize);
				} catch (e) {}
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	}

	function onImageLoad(e) {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	}
	useDebounceEffect(
		async () => {
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current
			) {
				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(
					imgRef.current,
					previewCanvasRef.current,
					completedCrop,
					scale,
					rotate
				);
			}
		},
		100,
		[completedCrop, scale, rotate]
	);

	function handleToggleAspectClick() {
		if (aspect) {
			setAspect(undefined);
		} else if (imgRef.current) {
			const { width, height } = imgRef.current;
			setAspect(16 / 9);
			setCrop(centerAspectCrop(width, height, 16 / 9));
		}
	}
	//Кнопка обрезать
	const onClickCropButton = async () => {
		//Обрезанное фото забираем из канваса
		const currentCroppedImage = previewCanvasRef?.current?.toDataURL(
			'image/png',
			0.8
		);
		setNewImageCropped(currentCroppedImage);
		//Сетаем новые размеры фото
		try {
			const imageSize = await getLoadingImageSize(currentCroppedImage);
			setSize(imageSize);
		} catch (e) {}
	};
	//Кнопка сброса обрезанного фото
	const onClickResetButton = async () => {
		setNewImageCropped(null); //Убираем обрезанное фото
		try {
			//Возвращаем размеры ориганльного фото
			const imageSize = await getLoadingImageSize(imgSrc);
			setSize(imageSize);
		} catch (e) {}
	};

	const styleForImage = !newImageCropped && classes.currentImage;

	return (
		<Dialog
			open={open}
			onClose={handleCloseImage}
			aria-labelledby="responsive-dialog-title"
			fullWidth={true}
			maxWidth="md"
		>
			<DialogTitle id="responsive-dialog-title">
				<div className={classes.title}>
					<div className={classes.text_title}>{textDialog}</div>
					<div>
						<input
							accept="image/*"
							style={{ display: 'none' }}
							id="images"
							type="file"
							onChange={onSelectFile}
							required
						/>
						<label htmlFor="images">
							<Button variant="contained" component="span">
								выбрать фото
							</Button>
						</label>
					</div>
					<div>
						<Button
							autoFocus
							onClick={handleCloseImage}
							variant="contained"
							color="secondary"
						>
							Закрыть
						</Button>
					</div>
				</div>
				<div>
					<CropImageDialogPanel
						imgSrc={imgSrc}
						setScale={setScale}
						setRotate={setRotate}
						handleToggleAspectClick={handleToggleAspectClick}
						aspect={aspect}
						onSelectFile={onSelectFile}
					/>
				</div>
			</DialogTitle>
			<DialogContent>
				{!!imgSrc && (
					<ReactCrop
						crop={crop}
						onChange={(_, percentCrop) => setCrop(percentCrop)}
						onComplete={(c) => setCompletedCrop(c)}
						aspect={aspect}
						className={styleForImage}
					>
						<img
							ref={imgRef}
							alt="Crop me"
							src={imgSrc}
							style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
							onLoad={onImageLoad}
						/>
					</ReactCrop>
				)}
				<div>
					{!!completedCrop && (
						<canvas
							ref={previewCanvasRef}
							style={{
								border: '1px solid black',
								objectFit: 'contain',
								width: completedCrop.width,
								height: completedCrop.height,
								display: 'none',
							}}
						/>
					)}

					{newImageCropped && (
						<>
							<img
								alt="Crop preview"
								src={newImageCropped}
								className={classes.crop_img}
							/>
						</>
					)}
				</div>
				{/* MAIN END  */}
			</DialogContent>
			<DialogActions style={{ margin: '0 auto' }} className={classes.footer}>
				{/* Error text */}
				{errorText && <span className={classes.errorText}>{errorText}</span>}
				{/* Buttons */}
				<div className={classes.buttons}>
					{imgSrc && (
						<Button
							onClick={onClickCropButton}
							disabled={!completedCrop}
							variant="contained"
							color="warning"
						>
							Обрезать
						</Button>
					)}
					{newImageCropped && (
						<Button
							onClick={onClickResetButton}
							variant="contained"
							color="primary"
						>
							Отменить
						</Button>
					)}

					{imgSrc && (
						<Button
							onClick={() => {
								//Отправка картинки обрезанной или оригинальной
								getCropImage(newImageCropped || imgSrc);
							}}
							variant="contained"
							color="success"
							className={classes.sendBtn}
							disabled={errorText}
						>
							Подтвердить
						</Button>
					)}
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default CropImageDialog;
