import { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReklamaService from '../../services/ReklamaService';
import styles from './reklamaCKEditor.module.css';
import { MyCKEditor } from '../../components/MyCKEditor/MyCKEditor';

export const AddReklamaCKEditor = () => {
	const navigate = useNavigate();

	const [description, setDescription] = useState('');

	const handleForm = (e) => {
		e.preventDefault();
		const dataForm = e.target;
		const formData = new FormData(dataForm);
		formData.append('description', description);

		(async () => {
			const resp = await ReklamaService.addReklama(formData);
			// console.log(resp)

			navigate(`/reklama`);
		})();
	};

	return (
		<div className={styles.reklamaWrapper}>
			<form className={styles.form} onSubmit={handleForm}>
				<Link to={'oldVersion'} className={styles.link}>
					Старая версия редактора
				</Link>
				<p>
					Заголовок <b>Пустым быть не может!</b>
				</p>
				<input placeholder="Введите заголовок, основной" name="title" />
				<p>Полное описание</p>
				<MyCKEditor
					onChange={(value) => setDescription(value)}
					className={styles.CKEditor}
				/>
				<p>Основной сайт компании (будет помещен в кнопку)</p>
				<input placeholder="https://yandex.ru/maps/" name="url" />

				<p>
					Выберете превью фото, небольшого разрешения{' '}
					<b>Пустым быть не может!</b>
				</p>
				<input type="file" name="preview_url" />

				<p>
					Выберете основное фото, разрешение побольше{' '}
					<b>Пустым быть не может!</b>
				</p>
				<input type="file" name="photo_url" />

				<p>Выберете до 5 файлов</p>
				<input type="file" multiple id="file-input" name="file_url" />

				<div className={styles.borderedDiv}>
					<p>
						Размещать или нет в <b>верхнем</b> блоке?
					</p>
					<p>Если 0 - не размещать совсем в этом блоке</p>
					<p>Выберете место в очереди от 1 до 12 </p>
					<p>
						<b>Пустым быть не может!</b>
					</p>
					<input
						type="number"
						defaultValue="0"
						min="0"
						max="12"
						name="top_place"
					/>
				</div>

				<div className={styles.borderedDiv}>
					<p>
						Размещать или нет в блоке <b>слева</b>?
					</p>
					<p>Если 0 - не размещать совсем в этом блоке</p>
					<p>Выберете место в очереди от 1 до 12</p>
					<p>
						<b>Пустым быть не может!</b>
					</p>
					<input
						type="number"
						defaultValue="0"
						min="0"
						max="12"
						name="side_place"
					/>
				</div>

				<div className={styles.borderedDiv}>
					<p>
						Размещать или нет в <b>карточках</b>?
					</p>
					<p>Если 0 - не размещать совсем в этом блоке</p>
					<p>Выберете место в очереди от 1 до 12</p>
					<p>
						<b>Пустым быть не может!</b>
					</p>
					<input
						type="number"
						defaultValue="0"
						min="0"
						max="12"
						name="card_place"
					/>
					<p>Текст который будет написан на карточке под Заголовком</p>
					<input placeholder="Введите текст карточки" name="card_text" />
				</div>

				<p>Оверлей (полупрозрачный фон, и текст на нем)</p>
				<input
					placeholder="Введите сайт"
					defaultValue="Спецпредложения для партнеров ассоциации"
					name="overlay"
				/>

				<button type="submite">Сохранить</button>
			</form>
		</div>
	);
};
