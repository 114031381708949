import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../hoc/AuthProvider';
import ZakazService from '../../../services/ZakazService';

export const useGetZakazes = () => {
	const { store } = useContext(AuthContext);

	const [allZakazes, setAllZakazes] = useState([]);

	useEffect(() => {
		fetchZakazes();
	}, []);

	async function fetchZakazes() {
		try {
			store?.setMessage('');
			store?.setLoading(true);
			const response = await ZakazService.fetchZakazes();
			console.log('fetch zakazes', response?.data);
			setAllZakazes(response?.data);
			store?.setLoading(false);
		} catch (error) {
			store?.setMessage(error?.response?.data?.message);
		} finally {
			store?.setLoading(false);
		}
	}

	return allZakazes;
};
