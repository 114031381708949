import React from 'react';
import './mainButton.css';

const MainButton = ({ handleClick, title, disabled }) => {
	return (
		<button disabled={disabled} className="mainButton" onClick={handleClick}>
			{title}
		</button>
	);
};

export default MainButton;
