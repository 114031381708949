/**
 * Получить размер загружаемого изображения.
 * @param {string} src - Путь к изображению для загрузки.
 * @returns {Promise<{width: number, height: number}>} Промис, который разрешается объектом содержащим ширину и высоту загруженного изображения.
 * @throws {Error} Если время загрузки изображения превысило 1000 проверок (10 секунд).
 */

export const getLoadingImageSize = (src) => {
	const image = new Image();
	image.src = src;

	return new Promise((res) => {
		let checkCount = 0;
		//Самовызывающаяся рекурсивная функция, ожидающая загрузки src в картинку
		(function checkImageLoaded() {
			if (checkCount === 100) throw new Error('Onload time limit is over');
			if (image.complete) {
				//Отдаем из промиса результат
				res({ width: image.width, height: image.height });
			} else {
				// Изображение еще не загружено, продолжаем проверки
				checkCount++;
				setTimeout(checkImageLoaded, 100); // Проверяем каждые 100 мс
			}
		})();
	});
};
