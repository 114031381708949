export const getStartDateFilter = (timePeriods, currIndexTimePeriod) => {
	const currentDate = new Date(); // Текущая дата
	// console.log('currentDate', currentDate);
	const msInDay = 24 * 60 * 60 * 1000;

	if (!currIndexTimePeriod) {
		return null;
	}

	if (timePeriods[currIndexTimePeriod].label === 'неделю') {
		return new Date(currentDate.getTime() - 7 * msInDay).toISOString();
	}
	if (timePeriods[currIndexTimePeriod].label === 'год') {
		return new Date(
			currentDate.getFullYear() - 1,
			currentDate.getMonth(),
			currentDate.getDate()
		).toISOString();
	}
	//Остальные случаи месяцы
	return new Date(
		currentDate.getFullYear(),
		currentDate.getMonth() - timePeriods[currIndexTimePeriod].value,
		currentDate.getDate()
	).toISOString();
};
