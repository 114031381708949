const allowedFiles = {
	photo: { 'image/jpeg': ['.jpeg', '.jpg', '.png'] },
	plantFiles: {
		'application/vnd.ms-excel': ['.xls'],
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
			'.xlsx',
		],
		'image/jpeg': ['.jpeg', '.jpg', '.png'],
		'image/gif': ['.gif'],
		'application/msword': ['.doc'],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
			'.docx',
		],
		'application/pdf': ['.pdf'],
	},
	draftFiles: {
		'application/vnd.ms-excel': ['.xls'],
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
			'.xlsx',
		],
		'image/jpeg': ['.jpeg', '.jpg', '.png'],
		'image/gif': ['.gif'],
		'application/msword': ['.doc'],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
			'.docx',
		],
		'application/pdf': ['.pdf'],
		// 'application/acad': ['.dwg', '.dvg'],
	},
};

module.exports = allowedFiles;
