import React, { useEffect, useState } from 'react';
import s from './usersStat.module.css';
import { Preloader } from '../../../components/Preloader/Preloader';
import { useGetUsersStat } from './useGetUsersStat';
import { FilterList } from '../../../components/FilterList/FilterList';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useParams } from 'react-router-dom';
import { StatUsersList } from './StatUsersList';
import { useScrollIntoView } from './useScrollIntoView';
import StatService from '../../../services/StatService';

export const UsersStatByUser = () => {
	const navigate = useNavigate();
	const params = useParams();
	const currentPage = +params.page;
	const itemPerPage = 10;

	const changeLocationPage = (page) => navigate('/statistics/users/' + page);
	//Состояние фильтров
	const userFilter = [{ label: 'Нет посещений' }, { label: 'Недавние визиты' }];
	const [currUserFilterIndex, setCurrUserFilterIndex] = useState(0);
	const isNotActiveUsers = currUserFilterIndex === 0;

	const onChageFilter = (filterIndex) => {
		setCurrUserFilterIndex(filterIndex);
		//Возвращаемся на 1 страницу если меняется фильтр
		changeLocationPage(1);
	};

	//Забираем с сервера данные не активных / активных пользователей
	const { usersData, usersTotalCount } = useGetUsersStat(isNotActiveUsers);
	const pageCount = Math.ceil(usersTotalCount / itemPerPage); //Число страниц

	//Колбэк Pagination
	const onChangePage = (_event, page) => {
		changeLocationPage(page);
	};

	//Покажем лоадер пока нет данных
	if (!usersData[0]) {
		return (
			<div className={s.wrapper}>
				<Preloader />
			</div>
		);
	}

	return (
		<>
			<FilterList
				dataItems={userFilter}
				currentItem={currUserFilterIndex}
				choiceItem={onChageFilter}
			/>
			<div className={s.paginationWrapper}>
				<Pagination
					count={pageCount}
					onChange={onChangePage}
					page={currentPage}
					shape="rounded"
				/>
			</div>
			<StatUsersList users={usersData} />
		</>
	);
};
