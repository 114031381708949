import $api from '../http';

export default class StatService {
	static async getActiveUsers(currentPage = 1) {
		return await $api.get('statistics/usersActivity/active/' + currentPage);
	}

	static async getNotActiveUsers(currentPage = 1) {
		return await $api.get('statistics/usersActivity/not-active/' + currentPage);
	}

	static async getDailyUsersVisit(params) {
		return await $api.get('statistics/usersActivity/daily-user-visit', {
			params,
		});
	}
}
