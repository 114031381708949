import React from 'react';
import { Button, Modal } from '@mui/material';
import s from './userCardModal.module.css';

export const UserCardModal = ({ userData, open, handleClose }) => {
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				{userData && (
					<div className={s.wrapper}>
						<table className={s.table}>
							<tr>
								<td>Организация</td>
								<td>{userData.org}</td>
							</tr>
							<tr>
								<td>Город</td>
								<td>{userData.cities}</td>
							</tr>
							<tr>
								<td>Имя</td>
								<td>{userData.name}</td>
							</tr>
							<tr>
								<td>Email</td>
								<td>{userData.email}</td>
							</tr>
							<tr>
								<td>Информация</td>
								<td>{userData.information}</td>
							</tr>
							<tr>
								<td>Описание</td>
								<td>{userData.description}</td>
							</tr>
						</table>
						<Button onClick={handleClose} color="primary" variant="contained">
							Закрыть
						</Button>
					</div>
				)}
			</Modal>
		</div>
	);
};
