import $api from '../../http';

export const Developer = () => {
	const onClickFixButton = async () => {
		const res = await $api.get('/developer/fixIsActualData');
		console.log(res);
	};

	return (
		<>
			<div>
				<h1>Developer mode</h1>
				<button onClick={onClickFixButton}>FixIsActualData</button>
			</div>
		</>
	);
};
