import s from './preloader.module.css';

export const Preloader = () => {
	return (
		<div className={s.lds}>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};
