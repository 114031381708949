import React from 'react';
import s from './zakazes.module.css';

export const StatHeader = ({ allCount, activeCount }) => {
	return (
		<>
			<div className={s.firstStatWrapper}>
				<p className={s.firstStatLabel}>за все время</p>
				<p>
					Всего заказов: <span className={s.statValue}>{allCount}</span>
				</p>
				<p>
					Активных заказов: <span className={s.statValue}>{activeCount}</span>
				</p>
			</div>
		</>
	);
};
