const config = {
	MAX_FILE: 10,
	// ApiUrl: 'http://localhost:5500/api',
	ApiUrl: 'https://settings3.copartner.ru/api',
	// UPLOAD_API_URL: 'http://localhost:5500',
	UPLOAD_API_URL: 'https://settings3.copartner.ru',
	uploadPlantPhoto: '/uploads/plant_ap/',
	uploadDraftPhoto: '/uploads/',
	RefreshUrl: '/users/refresh',
	adminPrefix: 'users',
	userPrefix: 'users_amp',
	MIN_HEIGHT_NEWS_PHOTO: 200
};

export default config;
