import React, { useMemo, useRef, useState } from 'react';
import s from './zakazes.module.css';
import { observer } from 'mobx-react-lite';
import { StatisticsList } from './StatisticsList';
import { useGetZakazes } from './useGetZakazes';
import { getGroups } from './utils/getGroups';
import { FilterList } from '../../../components/FilterList/FilterList';
import { Preloader } from '../../../components/Preloader/Preloader';
import { StatHeader } from './StatHeader';
import { TimeFilter } from './TimeFilter';
import { PageTitle } from '../../../components/PageTitle/PageTitle';
import { getTotalFiles } from './utils/getTotalFiles';

const ZakazesStat = () => {
	const allZakazes = useGetZakazes();

	//Создаем группу на основе всех заказов
	const allZakazesGroups = useMemo(() => getGroups(allZakazes), [allZakazes]);

	const startDates = allZakazesGroups.dates
		.filter((_el, i, arr) => i === 0 || i === arr.length - 1)
		.map((el) => el[0]);

	const allZakazesByWorks = allZakazesGroups.works.sort((a, b) => b[1] - a[1]);
	const allZakazesByCities = allZakazesGroups.cities.sort(
		(a, b) => b[1] - a[1]
	);
	const allZakazesByQuantity = allZakazesGroups.quantity;

	//----Состояния фильтров-----
	// Статус заказа
	const isActiveFilter = [{ label: 'все' }, { label: 'активные' }];
	const [currIsActiveFilter, setCurrIsActiveFilter] = useState(0);

	// Временные диапазоны
	const [filterTimes, setFilterTimes] = useState(null);

	//----Фильтрация заказов----
	let currZakazes = [...allZakazes];
	//По статусу
	if (isActiveFilter[currIsActiveFilter].label === 'активные') {
		currZakazes = currZakazes.filter((el) => el.enabled);
	}
	//По дате
	if (filterTimes) {
		currZakazes = currZakazes.filter((el) => el.date > filterTimes);
	}

	//Создаем группы на основе отфильтрованных заказов (текущих)
	const zakazesGroups = getGroups(currZakazes);

	//Фильтры для групп
	const groupFilters = [
		{ label: 'хронологии', value: 'dates' },
		{ label: 'видам работ', value: 'works' },
		{ label: 'городам', value: 'cities' },
		{ label: 'кол-ву деталей', value: 'quantity' },
	];

	const [currIndexGroupFilter, setCurrIndexGroupFilter] = useState(0);

	//Отбираем нужную группу заказов для показа на UI графиков - имеет вид [[key, value]...]
	const groupKey = groupFilters[currIndexGroupFilter].value;
	const filteredStatData = zakazesGroups[groupKey];

	//Данные для шапки страницы
	const activeCount = allZakazes.filter((el) => el.enabled).length;
	const allCount = allZakazes.length;

	//Количество файлов в текущих заказах
	const totalPhoto = getTotalFiles(currZakazes);

	return (
		<>
			<div className={s.wrapper}>
				<PageTitle>Заказы</PageTitle>
				{/* Шапка */}
				<StatHeader activeCount={activeCount} allCount={allCount} />
				<div className={s.filtersWrapper}>
					{/*  */}
					{/* Все или активные */}
					<p className={s.isActiveFilterText}>Статус</p>
					<FilterList
						dataItems={isActiveFilter}
						currentItem={currIsActiveFilter}
						choiceItem={setCurrIsActiveFilter}
					/>
					{/* Временной период */}
					<p className={s.isActiveFilterText}>Период за</p>
					<TimeFilter onChange={setFilterTimes} startDates={startDates} />
					{/*  */}
					{/* Группы заказов */}
					<p className={s.groupFiltersText}>Группировать по</p>
					<FilterList
						dataItems={groupFilters}
						currentItem={currIndexGroupFilter}
						choiceItem={setCurrIndexGroupFilter}
					/>
				</div>
				{allZakazes[0] ? (
					<StatisticsList dataList={filteredStatData} totalFiles={totalPhoto} />
				) : (
					<Preloader />
				)}
			</div>
		</>
	);
};

export default observer(ZakazesStat);
