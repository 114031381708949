import React from "react";
import { Container } from "@mui/material";
import "./pageNotFound.css";

const PageNotFound = () => {
  return (
    <>
      <div className="userList">
        <main>
          {/* Hero unit */}
          <Container sx={{ py: 8 }} maxWidth="xl">
            <div className="center">
              <div className="error">
                <div className="number">4</div>
                <div className="illustration">
                  <div className="circle"></div>
                  <div className="clip">
                    <div className="paper">
                      <div className="face">
                        <div className="eyes">
                          <div className="eye eye-left"></div>
                          <div className="eye eye-right"></div>
                        </div>
                        <div className="rosyCheeks rosyCheeks-left"></div>
                        <div className="rosyCheeks rosyCheeks-right"></div>
                        <div className="mouth"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="number">4</div>
              </div>

              <div className="text">Упс... страница не найдена</div>
            </div>
          </Container>
        </main>
      </div>
    </>
  );
};

export default PageNotFound;
