import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import s from './gridCellExpand.module.css';
import { ReactComponent as CopyIcon } from '../../img/copy-icon.svg';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';

function isOverflown(element) {
	return (
		element.scrollHeight > element.clientHeight ||
		element.scrollWidth > element.clientWidth
	);
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
	const { width, value, onClick, params } = props;
	const isDeleted = params.row?.deleted; //для заказов
	const wrapper = React.useRef(null);
	const cellDiv = React.useRef(null);
	const cellValue = React.useRef(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [showFullCell, setShowFullCell] = React.useState(false);
	const [showPopper, setShowPopper] = React.useState(false);
	const [showIcon, setShowCopyIcon] = React.useState(false);

	const handleMouseEnter = () => {
		const isCurrentlyOverflown = isOverflown(cellValue.current);
		setShowPopper(isCurrentlyOverflown);
		setAnchorEl(cellDiv.current);
		setShowFullCell(true);
	};

	const handleMouseLeave = () => {
		setShowFullCell(false);
	};

	useEffect(() => {
		if (!showFullCell) {
			return undefined;
		}

		function handleKeyDown(nativeEvent) {
			// IE11, Edge (prior to using Bink?) use 'Esc'
			if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
				setShowFullCell(false);
			}
		}

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [setShowFullCell, showFullCell]);

	const onSearchClick = (value) => {
		//Находим инпут поиска (MUI)
		const searchInput = document.querySelector('input[type="search"]');
		searchInput.select(); // Выбираем весь текст
		const event = new Event('input', {
			bubbles: true,
			cancelable: true,
			composed: true,
		});
		searchInput.setRangeText(value); // Заменяем выбранный текст
		searchInput.dispatchEvent(event); // Применяем событие для активации
	};

	return (
		<Box
			ref={wrapper}
			onMouseEnter={() => {
				handleMouseEnter();
				setShowCopyIcon(true);
			}}
			onMouseLeave={() => {
				handleMouseLeave();
				setShowCopyIcon(false);
			}}
			sx={{
				alignItems: 'center',
				lineHeight: '24px',
				width: 1,
				height: 1,
				position: 'relative',
				display: 'flex',
			}}
		>
			<Box
				ref={cellDiv}
				sx={{
					height: 1,
					width,
					display: 'block',
					position: 'absolute',
					top: 0,
				}}
				style={{ pointerEvents: 'none' }}
			/>
			<Box
				style={props.onClick && { cursor: 'pointer' }}
				onClick={onClick}
				ref={cellValue}
				sx={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				{value}
				{showIcon && props.isShowIcon && (
					<>
						<SearchIcon
							onClick={(e) => {
								e.stopPropagation();
								onSearchClick(value);
								// navigator.clipboard.writeText(value); //копирование
							}}
							className={clsx(s.searchIcon, isDeleted && s.deleted)}
						/>
					</>
				)}
			</Box>
			{showPopper && (
				<Popper
					open={showFullCell && anchorEl !== null}
					anchorEl={anchorEl}
					style={{ width, marginLeft: -17 }}
				>
					<Paper
						elevation={1}
						style={{ minHeight: wrapper.current.offsetHeight - 3 }}
					>
						<Typography variant="body2" style={{ padding: 8 }}>
							{value}
						</Typography>
					</Paper>
				</Popper>
			)}
		</Box>
	);
});

export default function renderCellExpand(params, onClick, isShowIcon) {
	return (
		<GridCellExpand
			value={params.value || ''}
			width={params.colDef.computedWidth}
			onClick={onClick}
			isShowIcon={isShowIcon}
			params={params}
		/>
	);
}

renderCellExpand.propTypes = {
	/**
	 * The column of the row that the current cell belongs to.
	 */
	colDef: PropTypes.object.isRequired,
	/**
	 * The cell value, but if the column has valueGetter, use getValue.
	 */
	value: PropTypes.string.isRequired,
	onClick: PropTypes.func,
};

GridCellExpand.propTypes = {
	value: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	onClick: PropTypes.func,
};
