import React, { useState } from 'react';
import { FilterList } from '../../../components/FilterList/FilterList';
import { getStartDateFilter } from './utils/getStartDateFilter';
import { TimeRangeInput } from '../../../components/TimeRangeInput/TimeRangeInput';

export const TimeFilter = ({ onChange, startDates }) => {
	const timePeriods = [
		{ label: 'все время' },
		{ label: 'неделю', value: 7 },
		{ label: 'месяц', value: 1 },
		{ label: 'квартал', value: 3 },
		{ label: 'полгода', value: 6 },
		{ label: 'год', value: 1 },
	];
	//Кнопки периодов
	const [currIndexTimePeriod, setCurrIndexTimePeriod] = useState(0);
	//Ручной выбор диапазона
	const [timeRangeData, setTimeRangeData] = useState(null);
	//Выбор периода по кнопкам - деактивируем ручной диапазон
	const onChangeIndexTimePeriod = (index) => {
		setCurrIndexTimePeriod(index);
		setTimeRangeData(null);
		const startDateFilter = getStartDateFilter(timePeriods, index);
		onChange(startDateFilter);
	};
	//Вызов ручного выбора диапазона - деактивируем кнопки
	const onActiveTimeRangeData = (timeDates) => {
		setTimeRangeData(timeDates);
		setCurrIndexTimePeriod(null);
	};

	return (
		<>
			{/* <TimeRangeInput
				startMinDate={startDates[1]}
				startMaxDate={startDates[0]}
				setActiveTimeRange={onActiveTimeRangeData}
				isActive={timeRangeData}
			/> */}
			<FilterList
				dataItems={timePeriods}
				currentItem={currIndexTimePeriod}
				choiceItem={onChangeIndexTimePeriod}
			/>
		</>
	);
};
