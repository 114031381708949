import { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../../hoc/AuthProvider';
import ZakazService from '../../../services/ZakazService';
import StatService from '../../../services/StatService';
import { useParams } from 'react-router-dom';

export const useGetUsersStat = (isNotActiveUsers) => {
	const { store } = useContext(AuthContext);
	const params = useParams();
	const currentPage = params.page;

	const [usersData, setUserData] = useState([]);
	const usersTotalCount = useRef(null);

	useEffect(() => {
		fetchZakazes();
	}, [isNotActiveUsers, currentPage]);

	async function fetchZakazes() {
		try {
			store?.setMessage('');
			store?.setLoading(true);
			let response;
			if (isNotActiveUsers) {
				response = await StatService.getNotActiveUsers(currentPage);
			} else {
				response = await StatService.getActiveUsers(currentPage);
			}
			//Сетаем полученные данные
			setUserData(response?.data);
			usersTotalCount.current = response.headers['x-total-users'];

			store?.setLoading(false);
		} catch (error) {
			store?.setMessage(error?.response?.data?.message);
		} finally {
			store?.setLoading(false);
		}
	}

	return { usersData, usersTotalCount: usersTotalCount.current };
};
