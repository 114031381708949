import ReactHtmlParser from 'html-react-parser';
import styles from './reklama.module.css';

const previewText = (props) => {
	return (
		<div className={styles.previewReklama}>
			<b>Как будет выглядеть описание:</b>
			<br /> <br />
			{ReactHtmlParser(props?.description)}
		</div>
	);
};

export default previewText;
