import React, { useEffect, useState } from 'react';
import StatService from '../../../services/StatService';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Preloader } from '../../../components/Preloader/Preloader';
import { StatisticsList } from '../zakaz/StatisticsList';

export const UsersStatByDays = () => {
	const [period, setPeriod] = useState(7);
	const [data, setData] = useState(null);

	useEffect(() => {
		(async () => {
			const response = await StatService.getDailyUsersVisit({
				lastDays: period,
			});
			setData(response.data);
		})();
	}, [period]);

	console.log(data);
	// Покажем лоадер пока нет данных
	if (!data) {
		return <Preloader />;
	}

	const formatData = data.map((item) => {
		const date = item.date.split('T')[0];
		const count = item.users.length;
		return [date, count];
	});

	return (
		<>
			<FormControl sx={{ minWidth: 140, mt: 1, mb: 1 }}>
				<InputLabel id="demo-simple-select-label">За период</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={period}
					label="За период"
					onChange={(event) => {
						setPeriod(event.target.value);
					}}
				>
					<MenuItem value={7}>7 дней</MenuItem>
					<MenuItem value={30}>30 дней</MenuItem>
					<MenuItem value={0}>Все время</MenuItem>
				</Select>
			</FormControl>
			<StatisticsList dataList={formatData} />
		</>
	);
};
