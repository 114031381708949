const russianCities = [
	{
		id: 1,
		value: 'Абаза',
	},
	{
		id: 2,
		value: 'Абакан',
	},
	{
		id: 3,
		value: 'Абдулино',
	},
	{
		id: 4,
		value: 'Абинск',
	},
	{
		id: 5,
		value: 'Агидель',
	},
	{
		id: 6,
		value: 'Агрыз',
	},
	{
		id: 7,
		value: 'Адыгейск',
	},
	{
		id: 8,
		value: 'Азнакаево',
	},
	{
		id: 9,
		value: 'Азов',
	},
	{
		id: 10,
		value: 'Ак-Довурак',
	},
	{
		id: 11,
		value: 'Аксай',
	},
	{
		id: 12,
		value: 'Алагир',
	},
	{
		id: 13,
		value: 'Алапаевск',
	},
	{
		id: 14,
		value: 'Алатырь',
	},
	{
		id: 15,
		value: 'Алдан',
	},
	{
		id: 16,
		value: 'Алейск',
	},
	{
		id: 17,
		value: 'Александров',
	},
	{
		id: 18,
		value: 'Александровск-Сахалинский',
	},
	{
		id: 19,
		value: 'Александровск',
	},
	{
		id: 20,
		value: 'Алексеевка',
	},
	{
		id: 21,
		value: 'Алексин',
	},
	{
		id: 22,
		value: 'Алзамай',
	},
	{
		id: 23,
		value: 'Алупка',
	},
	{
		id: 24,
		value: 'Алушта',
	},
	{
		id: 25,
		value: 'Альметьевск',
	},
	{
		id: 26,
		value: 'Амурск',
	},
	{
		id: 27,
		value: 'Анадырь',
	},
	{
		id: 28,
		value: 'Анапа',
	},
	{
		id: 29,
		value: 'Ангарск',
	},
	{
		id: 30,
		value: 'Андреаполь',
	},
	{
		id: 31,
		value: 'Анжеро-Судженск',
	},
	{
		id: 32,
		value: 'Анива',
	},
	{
		id: 33,
		value: 'Апатиты',
	},
	{
		id: 34,
		value: 'Апрелевка',
	},
	{
		id: 35,
		value: 'Апшеронск',
	},
	{
		id: 36,
		value: 'Арамиль',
	},
	{
		id: 37,
		value: 'Аргун',
	},
	{
		id: 38,
		value: 'Ардатов',
	},
	{
		id: 39,
		value: 'Ардон',
	},
	{
		id: 40,
		value: 'Арзамас',
	},
	{
		id: 41,
		value: 'Аркадак',
	},
	{
		id: 42,
		value: 'Армавир',
	},
	{
		id: 43,
		value: 'Армянск',
	},
	{
		id: 44,
		value: 'Арсеньев',
	},
	{
		id: 45,
		value: 'Арск',
	},
	{
		id: 46,
		value: 'Артём',
	},
	{
		id: 47,
		value: 'Артёмовск',
	},
	{
		id: 48,
		value: 'Артёмовский',
	},
	{
		id: 49,
		value: 'Архангельск',
	},
	{
		id: 50,
		value: 'Асбест',
	},
	{
		id: 51,
		value: 'Асино',
	},
	{
		id: 52,
		value: 'Астрахань',
	},
	{
		id: 53,
		value: 'Аткарск',
	},
	{
		id: 54,
		value: 'Ахтубинск',
	},
	{
		id: 55,
		value: 'Ачинск',
	},
	{
		id: 56,
		value: 'Аша',
	},
	{
		id: 57,
		value: 'Бабаево',
	},
	{
		id: 58,
		value: 'Бабушкин',
	},
	{
		id: 59,
		value: 'Бавлы',
	},
	{
		id: 60,
		value: 'Багратионовск',
	},
	{
		id: 61,
		value: 'Байкальск',
	},
	{
		id: 62,
		value: 'Баймак',
	},
	{
		id: 63,
		value: 'Бакал',
	},
	{
		id: 64,
		value: 'Баксан',
	},
	{
		id: 65,
		value: 'Балабаново',
	},
	{
		id: 66,
		value: 'Балаково',
	},
	{
		id: 67,
		value: 'Балахна',
	},
	{
		id: 68,
		value: 'Балашиха',
	},
	{
		id: 69,
		value: 'Балашов',
	},
	{
		id: 70,
		value: 'Балей',
	},
	{
		id: 71,
		value: 'Балтийск',
	},
	{
		id: 72,
		value: 'Барабинск',
	},
	{
		id: 73,
		value: 'Барнаул',
	},
	{
		id: 74,
		value: 'Барыш',
	},
	{
		id: 75,
		value: 'Батайск',
	},
	{
		id: 76,
		value: 'Бахчисарай',
	},
	{
		id: 77,
		value: 'Бежецк',
	},
	{
		id: 78,
		value: 'Белая Калитва',
	},
	{
		id: 79,
		value: 'Белая Холуница',
	},
	{
		id: 80,
		value: 'Белгород',
	},
	{
		id: 81,
		value: 'Белебей',
	},
	{
		id: 82,
		value: 'Белинский',
	},
	{
		id: 83,
		value: 'Белово',
	},
	{
		id: 84,
		value: 'Белогорск',
	},
	{
		id: 85,
		value: 'Белозерск',
	},
	{
		id: 86,
		value: 'Белокуриха',
	},
	{
		id: 87,
		value: 'Беломорск',
	},
	{
		id: 88,
		value: 'Белоозёрский',
	},
	{
		id: 89,
		value: 'Белорецк',
	},
	{
		id: 90,
		value: 'Белореченск',
	},
	{
		id: 91,
		value: 'Белоусово',
	},
	{
		id: 92,
		value: 'Белоярский',
	},
	{
		id: 93,
		value: 'Белый',
	},
	{
		id: 94,
		value: 'Белёв',
	},
	{
		id: 95,
		value: 'Бердск',
	},
	{
		id: 96,
		value: 'Березники',
	},
	{
		id: 97,
		value: 'Берёзовский',
	},
	{
		id: 98,
		value: 'Беслан',
	},
	{
		id: 99,
		value: 'Бийск',
	},
	{
		id: 100,
		value: 'Бикин',
	},
	{
		id: 101,
		value: 'Билибино',
	},
	{
		id: 102,
		value: 'Биробиджан',
	},
	{
		id: 103,
		value: 'Бирск',
	},
	{
		id: 104,
		value: 'Бирюсинск',
	},
	{
		id: 105,
		value: 'Бирюч',
	},
	{
		id: 106,
		value: 'Благовещенск',
	},
	{
		id: 107,
		value: 'Благодарный',
	},
	{
		id: 108,
		value: 'Бобров',
	},
	{
		id: 109,
		value: 'Богданович',
	},
	{
		id: 110,
		value: 'Богородицк',
	},
	{
		id: 111,
		value: 'Богородск',
	},
	{
		id: 112,
		value: 'Боготол',
	},
	{
		id: 113,
		value: 'Богучар',
	},
	{
		id: 114,
		value: 'Бодайбо',
	},
	{
		id: 115,
		value: 'Бокситогорск',
	},
	{
		id: 116,
		value: 'Болгар',
	},
	{
		id: 117,
		value: 'Бологое',
	},
	{
		id: 118,
		value: 'Болотное',
	},
	{
		id: 119,
		value: 'Болохово',
	},
	{
		id: 120,
		value: 'Болхов',
	},
	{
		id: 121,
		value: 'Большой Камень',
	},
	{
		id: 122,
		value: 'Бор',
	},
	{
		id: 123,
		value: 'Борзя',
	},
	{
		id: 124,
		value: 'Борисоглебск',
	},
	{
		id: 125,
		value: 'Боровичи',
	},
	{
		id: 126,
		value: 'Боровск',
	},
	{
		id: 127,
		value: 'Бородино',
	},
	{
		id: 128,
		value: 'Братск',
	},
	{
		id: 129,
		value: 'Бронницы',
	},
	{
		id: 130,
		value: 'Брянск',
	},
	{
		id: 131,
		value: 'Бугульма',
	},
	{
		id: 132,
		value: 'Бугуруслан',
	},
	{
		id: 133,
		value: 'Будённовск',
	},
	{
		id: 134,
		value: 'Бузулук',
	},
	{
		id: 135,
		value: 'Буинск',
	},
	{
		id: 136,
		value: 'Буй',
	},
	{
		id: 137,
		value: 'Буйнакск',
	},
	{
		id: 138,
		value: 'Бутурлиновка',
	},
	{
		id: 139,
		value: 'Валдай',
	},
	{
		id: 140,
		value: 'Валуйки',
	},
	{
		id: 141,
		value: 'Велиж',
	},
	{
		id: 142,
		value: 'Великие Луки',
	},
	{
		id: 143,
		value: 'Великий Новгород',
	},
	{
		id: 144,
		value: 'Великий Устюг',
	},
	{
		id: 145,
		value: 'Вельск',
	},
	{
		id: 146,
		value: 'Венёв',
	},
	{
		id: 147,
		value: 'Верещагино',
	},
	{
		id: 148,
		value: 'Верея',
	},
	{
		id: 149,
		value: 'Верхнеуральск',
	},
	{
		id: 150,
		value: 'Верхний Тагил',
	},
	{
		id: 151,
		value: 'Верхний Уфалей',
	},
	{
		id: 152,
		value: 'Верхняя Пышма',
	},
	{
		id: 153,
		value: 'Верхняя Салда',
	},
	{
		id: 154,
		value: 'Верхняя Тура',
	},
	{
		id: 155,
		value: 'Верхотурье',
	},
	{
		id: 156,
		value: 'Верхоянск',
	},
	{
		id: 157,
		value: 'Весьегонск',
	},
	{
		id: 158,
		value: 'Ветлуга',
	},
	{
		id: 159,
		value: 'Видное',
	},
	{
		id: 160,
		value: 'Вилюйск',
	},
	{
		id: 161,
		value: 'Вилючинск',
	},
	{
		id: 162,
		value: 'Вихоревка',
	},
	{
		id: 163,
		value: 'Вичуга',
	},
	{
		id: 164,
		value: 'Владивосток',
	},
	{
		id: 165,
		value: 'Владикавказ',
	},
	{
		id: 166,
		value: 'Владимир',
	},
	{
		id: 167,
		value: 'Волгоград',
	},
	{
		id: 168,
		value: 'Волгодонск',
	},
	{
		id: 169,
		value: 'Волгореченск',
	},
	{
		id: 170,
		value: 'Волжск',
	},
	{
		id: 171,
		value: 'Волжский',
	},
	{
		id: 172,
		value: 'Вологда',
	},
	{
		id: 173,
		value: 'Володарск',
	},
	{
		id: 174,
		value: 'Волоколамск',
	},
	{
		id: 175,
		value: 'Волосово',
	},
	{
		id: 176,
		value: 'Волхов',
	},
	{
		id: 177,
		value: 'Волчанск',
	},
	{
		id: 178,
		value: 'Вольск',
	},
	{
		id: 179,
		value: 'Воркута',
	},
	{
		id: 180,
		value: 'Воронеж',
	},
	{
		id: 181,
		value: 'Ворсма',
	},
	{
		id: 182,
		value: 'Воскресенск',
	},
	{
		id: 183,
		value: 'Воткинск',
	},
	{
		id: 184,
		value: 'Всеволожск',
	},
	{
		id: 185,
		value: 'Вуктыл',
	},
	{
		id: 186,
		value: 'Выборг',
	},
	{
		id: 187,
		value: 'Выкса',
	},
	{
		id: 188,
		value: 'Высоковск',
	},
	{
		id: 189,
		value: 'Высоцк',
	},
	{
		id: 190,
		value: 'Вытегра',
	},
	{
		id: 191,
		value: 'Вышний Волочёк',
	},
	{
		id: 192,
		value: 'Вяземский',
	},
	{
		id: 193,
		value: 'Вязники',
	},
	{
		id: 194,
		value: 'Вязьма',
	},
	{
		id: 195,
		value: 'Вятские Поляны',
	},
	{
		id: 196,
		value: 'Гаврилов Посад',
	},
	{
		id: 197,
		value: 'Гаврилов-Ям',
	},
	{
		id: 198,
		value: 'Гагарин',
	},
	{
		id: 199,
		value: 'Гаджиево',
	},
	{
		id: 200,
		value: 'Гай',
	},
	{
		id: 201,
		value: 'Галич',
	},
	{
		id: 202,
		value: 'Гатчина',
	},
	{
		id: 203,
		value: 'Гвардейск',
	},
	{
		id: 204,
		value: 'Гдов',
	},
	{
		id: 205,
		value: 'Геленджик',
	},
	{
		id: 206,
		value: 'Георгиевск',
	},
	{
		id: 207,
		value: 'Глазов',
	},
	{
		id: 208,
		value: 'Голицыно',
	},
	{
		id: 209,
		value: 'Горбатов',
	},
	{
		id: 210,
		value: 'Горно-Алтайск',
	},
	{
		id: 211,
		value: 'Горнозаводск',
	},
	{
		id: 212,
		value: 'Горняк',
	},
	{
		id: 213,
		value: 'Городец',
	},
	{
		id: 214,
		value: 'Городище',
	},
	{
		id: 215,
		value: 'Городовиковск',
	},
	{
		id: 216,
		value: 'Гороховец',
	},
	{
		id: 217,
		value: 'Горячий Ключ',
	},
	{
		id: 218,
		value: 'Грайворон',
	},
	{
		id: 219,
		value: 'Гремячинск',
	},
	{
		id: 220,
		value: 'Грозный',
	},
	{
		id: 221,
		value: 'Грязи',
	},
	{
		id: 222,
		value: 'Грязовец',
	},
	{
		id: 223,
		value: 'Губаха',
	},
	{
		id: 224,
		value: 'Губкин',
	},
	{
		id: 225,
		value: 'Губкинский',
	},
	{
		id: 226,
		value: 'Гудермес',
	},
	{
		id: 227,
		value: 'Гуково',
	},
	{
		id: 228,
		value: 'Гулькевичи',
	},
	{
		id: 229,
		value: 'Гурьевск',
	},
	{
		id: 230,
		value: 'Гусев',
	},
	{
		id: 231,
		value: 'Гусиноозёрск',
	},
	{
		id: 232,
		value: 'Гусь-Хрустальный',
	},
	{
		id: 233,
		value: 'Давлеканово',
	},
	{
		id: 234,
		value: 'Дагестанские Огни',
	},
	{
		id: 235,
		value: 'Далматово',
	},
	{
		id: 236,
		value: 'Дальнегорск',
	},
	{
		id: 237,
		value: 'Дальнереченск',
	},
	{
		id: 238,
		value: 'Данилов',
	},
	{
		id: 239,
		value: 'Данков',
	},
	{
		id: 240,
		value: 'Дегтярск',
	},
	{
		id: 241,
		value: 'Дедовск',
	},
	{
		id: 242,
		value: 'Демидов',
	},
	{
		id: 243,
		value: 'Дербент',
	},
	{
		id: 244,
		value: 'Десногорск',
	},
	{
		id: 245,
		value: 'Джанкой',
	},
	{
		id: 246,
		value: 'Дзержинск',
	},
	{
		id: 247,
		value: 'Дзержинский',
	},
	{
		id: 248,
		value: 'Дивногорск',
	},
	{
		id: 249,
		value: 'Дигора',
	},
	{
		id: 250,
		value: 'Димитровград',
	},
	{
		id: 251,
		value: 'Дмитриев',
	},
	{
		id: 252,
		value: 'Дмитров',
	},
	{
		id: 253,
		value: 'Дмитровск',
	},
	{
		id: 254,
		value: 'Дно',
	},
	{
		id: 255,
		value: 'Добрянка',
	},
	{
		id: 256,
		value: 'Долгопрудный',
	},
	{
		id: 257,
		value: 'Долинск',
	},
	{
		id: 258,
		value: 'Домодедово',
	},
	{
		id: 259,
		value: 'Донецк',
	},
	{
		id: 260,
		value: 'Донской',
	},
	{
		id: 261,
		value: 'Дорогобуж',
	},
	{
		id: 262,
		value: 'Дрезна',
	},
	{
		id: 263,
		value: 'Дубна',
	},
	{
		id: 264,
		value: 'Дубовка',
	},
	{
		id: 265,
		value: 'Дудинка',
	},
	{
		id: 266,
		value: 'Духовщина',
	},
	{
		id: 267,
		value: 'Дюртюли',
	},
	{
		id: 268,
		value: 'Дятьково',
	},
	{
		id: 269,
		value: 'Евпатория',
	},
	{
		id: 270,
		value: 'Егорьевск',
	},
	{
		id: 271,
		value: 'Ейск',
	},
	{
		id: 272,
		value: 'Екатеринбург',
	},
	{
		id: 273,
		value: 'Елабуга',
	},
	{
		id: 274,
		value: 'Елец',
	},
	{
		id: 275,
		value: 'Елизово',
	},
	{
		id: 276,
		value: 'Ельня',
	},
	{
		id: 277,
		value: 'Еманжелинск',
	},
	{
		id: 278,
		value: 'Емва',
	},
	{
		id: 279,
		value: 'Енисейск',
	},
	{
		id: 280,
		value: 'Ермолино',
	},
	{
		id: 281,
		value: 'Ершов',
	},
	{
		id: 282,
		value: 'Ессентуки',
	},
	{
		id: 283,
		value: 'Ефремов',
	},
	{
		id: 284,
		value: 'Железноводск',
	},
	{
		id: 285,
		value: 'Железногорск-Илимский',
	},
	{
		id: 286,
		value: 'Железногорск',
	},
	{
		id: 287,
		value: 'Жердевка',
	},
	{
		id: 288,
		value: 'Жигулёвск',
	},
	{
		id: 289,
		value: 'Жиздра',
	},
	{
		id: 290,
		value: 'Жирновск',
	},
	{
		id: 291,
		value: 'Жуков',
	},
	{
		id: 292,
		value: 'Жуковка',
	},
	{
		id: 293,
		value: 'Жуковский',
	},
	{
		id: 294,
		value: 'Завитинск',
	},
	{
		id: 295,
		value: 'Заводоуковск',
	},
	{
		id: 296,
		value: 'Заволжск',
	},
	{
		id: 297,
		value: 'Заволжье',
	},
	{
		id: 298,
		value: 'Задонск',
	},
	{
		id: 299,
		value: 'Заинск',
	},
	{
		id: 300,
		value: 'Закаменск',
	},
	{
		id: 301,
		value: 'Заозёрный',
	},
	{
		id: 302,
		value: 'Заозёрск',
	},
	{
		id: 303,
		value: 'Западная Двина',
	},
	{
		id: 304,
		value: 'Заполярный',
	},
	{
		id: 305,
		value: 'Зарайск',
	},
	{
		id: 306,
		value: 'Заречный',
	},
	{
		id: 307,
		value: 'Заринск',
	},
	{
		id: 308,
		value: 'Звенигово',
	},
	{
		id: 309,
		value: 'Звенигород',
	},
	{
		id: 310,
		value: 'Зверево',
	},
	{
		id: 311,
		value: 'Зеленогорск',
	},
	{
		id: 312,
		value: 'Зеленоградск',
	},
	{
		id: 313,
		value: 'Зеленодольск',
	},
	{
		id: 314,
		value: 'Зеленокумск',
	},
	{
		id: 315,
		value: 'Зерноград',
	},
	{
		id: 316,
		value: 'Зея',
	},
	{
		id: 317,
		value: 'Зима',
	},
	{
		id: 318,
		value: 'Златоуст',
	},
	{
		id: 319,
		value: 'Злынка',
	},
	{
		id: 320,
		value: 'Змеиногорск',
	},
	{
		id: 321,
		value: 'Знаменск',
	},
	{
		id: 322,
		value: 'Зубцов',
	},
	{
		id: 323,
		value: 'Зуевка',
	},
	{
		id: 324,
		value: 'Ивангород',
	},
	{
		id: 325,
		value: 'Иваново',
	},
	{
		id: 326,
		value: 'Ивантеевка',
	},
	{
		id: 327,
		value: 'Ивдель',
	},
	{
		id: 328,
		value: 'Игарка',
	},
	{
		id: 329,
		value: 'Ижевск',
	},
	{
		id: 330,
		value: 'Избербаш',
	},
	{
		id: 331,
		value: 'Изобильный',
	},
	{
		id: 332,
		value: 'Иланский',
	},
	{
		id: 333,
		value: 'Инза',
	},
	{
		id: 334,
		value: 'Иннополис',
	},
	{
		id: 335,
		value: 'Инсар',
	},
	{
		id: 336,
		value: 'Инта',
	},
	{
		id: 337,
		value: 'Ипатово',
	},
	{
		id: 338,
		value: 'Ирбит',
	},
	{
		id: 339,
		value: 'Иркутск',
	},
	{
		id: 340,
		value: 'Исилькуль',
	},
	{
		id: 341,
		value: 'Искитим',
	},
	{
		id: 342,
		value: 'Истра',
	},
	{
		id: 343,
		value: 'Ишим',
	},
	{
		id: 344,
		value: 'Ишимбай',
	},
	{
		id: 345,
		value: 'Йошкар-Ола',
	},
	{
		id: 346,
		value: 'Кадников',
	},
	{
		id: 347,
		value: 'Казань',
	},
	{
		id: 348,
		value: 'Калач-на-Дону',
	},
	{
		id: 349,
		value: 'Калач',
	},
	{
		id: 350,
		value: 'Калачинск',
	},
	{
		id: 351,
		value: 'Калининград',
	},
	{
		id: 352,
		value: 'Калининск',
	},
	{
		id: 353,
		value: 'Калтан',
	},
	{
		id: 354,
		value: 'Калуга',
	},
	{
		id: 355,
		value: 'Калязин',
	},
	{
		id: 356,
		value: 'Камбарка',
	},
	{
		id: 357,
		value: 'Каменка',
	},
	{
		id: 358,
		value: 'Каменногорск',
	},
	{
		id: 359,
		value: 'Каменск-Уральский',
	},
	{
		id: 360,
		value: 'Каменск-Шахтинский',
	},
	{
		id: 361,
		value: 'Камень-на-Оби',
	},
	{
		id: 362,
		value: 'Камешково',
	},
	{
		id: 363,
		value: 'Камызяк',
	},
	{
		id: 364,
		value: 'Камышин',
	},
	{
		id: 365,
		value: 'Камышлов',
	},
	{
		id: 366,
		value: 'Канаш',
	},
	{
		id: 367,
		value: 'Кандалакша',
	},
	{
		id: 368,
		value: 'Канск',
	},
	{
		id: 369,
		value: 'Карабаново',
	},
	{
		id: 370,
		value: 'Карабаш',
	},
	{
		id: 371,
		value: 'Карабулак',
	},
	{
		id: 372,
		value: 'Карасук',
	},
	{
		id: 373,
		value: 'Карачаевск',
	},
	{
		id: 374,
		value: 'Карачев',
	},
	{
		id: 375,
		value: 'Каргат',
	},
	{
		id: 376,
		value: 'Каргополь',
	},
	{
		id: 377,
		value: 'Карпинск',
	},
	{
		id: 378,
		value: 'Карталы',
	},
	{
		id: 379,
		value: 'Касимов',
	},
	{
		id: 380,
		value: 'Касли',
	},
	{
		id: 381,
		value: 'Каспийск',
	},
	{
		id: 382,
		value: 'Катав-Ивановск',
	},
	{
		id: 383,
		value: 'Катайск',
	},
	{
		id: 384,
		value: 'Качканар',
	},
	{
		id: 385,
		value: 'Кашин',
	},
	{
		id: 386,
		value: 'Кашира',
	},
	{
		id: 387,
		value: 'Кедровый',
	},
	{
		id: 388,
		value: 'Кемерово',
	},
	{
		id: 389,
		value: 'Кемь',
	},
	{
		id: 390,
		value: 'Керчь',
	},
	{
		id: 391,
		value: 'Кизел',
	},
	{
		id: 392,
		value: 'Кизилюрт',
	},
	{
		id: 393,
		value: 'Кизляр',
	},
	{
		id: 394,
		value: 'Кимовск',
	},
	{
		id: 395,
		value: 'Кимры',
	},
	{
		id: 396,
		value: 'Кингисепп',
	},
	{
		id: 397,
		value: 'Кинель',
	},
	{
		id: 398,
		value: 'Кинешма',
	},
	{
		id: 399,
		value: 'Киреевск',
	},
	{
		id: 400,
		value: 'Киренск',
	},
	{
		id: 401,
		value: 'Киржач',
	},
	{
		id: 402,
		value: 'Кириллов',
	},
	{
		id: 403,
		value: 'Кириши',
	},
	{
		id: 404,
		value: 'Киров',
	},
	{
		id: 405,
		value: 'Кировград',
	},
	{
		id: 406,
		value: 'Кирово-Чепецк',
	},
	{
		id: 407,
		value: 'Кировск',
	},
	{
		id: 408,
		value: 'Кирс',
	},
	{
		id: 409,
		value: 'Кирсанов',
	},
	{
		id: 410,
		value: 'Киселёвск',
	},
	{
		id: 411,
		value: 'Кисловодск',
	},
	{
		id: 412,
		value: 'Клин',
	},
	{
		id: 413,
		value: 'Клинцы',
	},
	{
		id: 414,
		value: 'Княгинино',
	},
	{
		id: 415,
		value: 'Ковдор',
	},
	{
		id: 416,
		value: 'Ковров',
	},
	{
		id: 417,
		value: 'Ковылкино',
	},
	{
		id: 418,
		value: 'Когалым',
	},
	{
		id: 419,
		value: 'Кодинск',
	},
	{
		id: 420,
		value: 'Козельск',
	},
	{
		id: 421,
		value: 'Козловка',
	},
	{
		id: 422,
		value: 'Козьмодемьянск',
	},
	{
		id: 423,
		value: 'Кола',
	},
	{
		id: 424,
		value: 'Кологрив',
	},
	{
		id: 425,
		value: 'Коломна',
	},
	{
		id: 426,
		value: 'Колпашево',
	},
	{
		id: 427,
		value: 'Кольчугино',
	},
	{
		id: 428,
		value: 'Коммунар',
	},
	{
		id: 429,
		value: 'Комсомольск-на-Амуре',
	},
	{
		id: 430,
		value: 'Комсомольск',
	},
	{
		id: 431,
		value: 'Конаково',
	},
	{
		id: 432,
		value: 'Кондопога',
	},
	{
		id: 433,
		value: 'Кондрово',
	},
	{
		id: 434,
		value: 'Константиновск',
	},
	{
		id: 435,
		value: 'Копейск',
	},
	{
		id: 436,
		value: 'Кораблино',
	},
	{
		id: 437,
		value: 'Кореновск',
	},
	{
		id: 438,
		value: 'Коркино',
	},
	{
		id: 439,
		value: 'Королёв',
	},
	{
		id: 440,
		value: 'Короча',
	},
	{
		id: 441,
		value: 'Корсаков',
	},
	{
		id: 442,
		value: 'Коряжма',
	},
	{
		id: 443,
		value: 'Костерёво',
	},
	{
		id: 444,
		value: 'Костомукша',
	},
	{
		id: 445,
		value: 'Кострома',
	},
	{
		id: 446,
		value: 'Котельники',
	},
	{
		id: 447,
		value: 'Котельниково',
	},
	{
		id: 448,
		value: 'Котельнич',
	},
	{
		id: 449,
		value: 'Котлас',
	},
	{
		id: 450,
		value: 'Котово',
	},
	{
		id: 451,
		value: 'Котовск',
	},
	{
		id: 452,
		value: 'Кохма',
	},
	{
		id: 453,
		value: 'Красавино',
	},
	{
		id: 454,
		value: 'Красноармейск',
	},
	{
		id: 455,
		value: 'Красновишерск',
	},
	{
		id: 456,
		value: 'Красногорск',
	},
	{
		id: 457,
		value: 'Краснодар',
	},
	{
		id: 458,
		value: 'Краснозаводск',
	},
	{
		id: 459,
		value: 'Краснознаменск',
	},
	{
		id: 460,
		value: 'Краснокаменск',
	},
	{
		id: 461,
		value: 'Краснокамск',
	},
	{
		id: 462,
		value: 'Красноперекопск',
	},
	{
		id: 463,
		value: 'Краснослободск',
	},
	{
		id: 464,
		value: 'Краснотурьинск',
	},
	{
		id: 465,
		value: 'Красноуральск',
	},
	{
		id: 466,
		value: 'Красноуфимск',
	},
	{
		id: 467,
		value: 'Красноярск',
	},
	{
		id: 468,
		value: 'Красный Кут',
	},
	{
		id: 469,
		value: 'Красный Сулин',
	},
	{
		id: 470,
		value: 'Красный Холм',
	},
	{
		id: 471,
		value: 'Кремёнки',
	},
	{
		id: 472,
		value: 'Кропоткин',
	},
	{
		id: 473,
		value: 'Крымск',
	},
	{
		id: 474,
		value: 'Кстово',
	},
	{
		id: 475,
		value: 'Кубинка',
	},
	{
		id: 476,
		value: 'Кувандык',
	},
	{
		id: 477,
		value: 'Кувшиново',
	},
	{
		id: 478,
		value: 'Кудрово',
	},
	{
		id: 479,
		value: 'Кудымкар',
	},
	{
		id: 480,
		value: 'Кузнецк',
	},
	{
		id: 481,
		value: 'Куйбышев',
	},
	{
		id: 482,
		value: 'Кукмор',
	},
	{
		id: 483,
		value: 'Кулебаки',
	},
	{
		id: 484,
		value: 'Кумертау',
	},
	{
		id: 485,
		value: 'Кунгур',
	},
	{
		id: 486,
		value: 'Купино',
	},
	{
		id: 487,
		value: 'Курган',
	},
	{
		id: 488,
		value: 'Курганинск',
	},
	{
		id: 489,
		value: 'Курильск',
	},
	{
		id: 490,
		value: 'Курлово',
	},
	{
		id: 491,
		value: 'Куровское',
	},
	{
		id: 492,
		value: 'Курск',
	},
	{
		id: 493,
		value: 'Куртамыш',
	},
	{
		id: 494,
		value: 'Курчалой',
	},
	{
		id: 495,
		value: 'Курчатов',
	},
	{
		id: 496,
		value: 'Куса',
	},
	{
		id: 497,
		value: 'Кушва',
	},
	{
		id: 498,
		value: 'Кызыл',
	},
	{
		id: 499,
		value: 'Кыштым',
	},
	{
		id: 500,
		value: 'Кяхта',
	},
	{
		id: 501,
		value: 'Лабинск',
	},
	{
		id: 502,
		value: 'Лабытнанги',
	},
	{
		id: 503,
		value: 'Лагань',
	},
	{
		id: 504,
		value: 'Ладушкин',
	},
	{
		id: 505,
		value: 'Лаишево',
	},
	{
		id: 506,
		value: 'Лакинск',
	},
	{
		id: 507,
		value: 'Лангепас',
	},
	{
		id: 508,
		value: 'Лахденпохья',
	},
	{
		id: 509,
		value: 'Лебедянь',
	},
	{
		id: 510,
		value: 'Лениногорск',
	},
	{
		id: 511,
		value: 'Ленинск-Кузнецкий',
	},
	{
		id: 512,
		value: 'Ленинск',
	},
	{
		id: 513,
		value: 'Ленск',
	},
	{
		id: 514,
		value: 'Лермонтов',
	},
	{
		id: 515,
		value: 'Лесной',
	},
	{
		id: 516,
		value: 'Лесозаводск',
	},
	{
		id: 517,
		value: 'Лесосибирск',
	},
	{
		id: 518,
		value: 'Ливны',
	},
	{
		id: 519,
		value: 'Ликино-Дулёво',
	},
	{
		id: 520,
		value: 'Липецк',
	},
	{
		id: 521,
		value: 'Липки',
	},
	{
		id: 522,
		value: 'Лиски',
	},
	{
		id: 523,
		value: 'Лихославль',
	},
	{
		id: 524,
		value: 'Лобня',
	},
	{
		id: 525,
		value: 'Лодейное Поле',
	},
	{
		id: 526,
		value: 'Лосино-Петровский',
	},
	{
		id: 527,
		value: 'Луга',
	},
	{
		id: 528,
		value: 'Луза',
	},
	{
		id: 529,
		value: 'Лукоянов',
	},
	{
		id: 530,
		value: 'Луховицы',
	},
	{
		id: 531,
		value: 'Лысково',
	},
	{
		id: 532,
		value: 'Лысьва',
	},
	{
		id: 533,
		value: 'Лыткарино',
	},
	{
		id: 534,
		value: 'Льгов',
	},
	{
		id: 535,
		value: 'Любань',
	},
	{
		id: 536,
		value: 'Люберцы',
	},
	{
		id: 537,
		value: 'Любим',
	},
	{
		id: 538,
		value: 'Людиново',
	},
	{
		id: 539,
		value: 'Лянтор',
	},
	{
		id: 540,
		value: 'Магадан',
	},
	{
		id: 541,
		value: 'Магас',
	},
	{
		id: 542,
		value: 'Магнитогорск',
	},
	{
		id: 543,
		value: 'Майкоп',
	},
	{
		id: 544,
		value: 'Майский',
	},
	{
		id: 545,
		value: 'Макаров',
	},
	{
		id: 546,
		value: 'Макарьев',
	},
	{
		id: 547,
		value: 'Макушино',
	},
	{
		id: 548,
		value: 'Малая Вишера',
	},
	{
		id: 549,
		value: 'Малгобек',
	},
	{
		id: 550,
		value: 'Малмыж',
	},
	{
		id: 551,
		value: 'Малоархангельск',
	},
	{
		id: 552,
		value: 'Малоярославец',
	},
	{
		id: 553,
		value: 'Мамадыш',
	},
	{
		id: 554,
		value: 'Мамоново',
	},
	{
		id: 555,
		value: 'Мантурово',
	},
	{
		id: 556,
		value: 'Мариинск',
	},
	{
		id: 557,
		value: 'Мариинский Посад',
	},
	{
		id: 558,
		value: 'Маркс',
	},
	{
		id: 559,
		value: 'Махачкала',
	},
	{
		id: 560,
		value: 'Мглин',
	},
	{
		id: 561,
		value: 'Мегион',
	},
	{
		id: 562,
		value: 'Медвежьегорск',
	},
	{
		id: 563,
		value: 'Медногорск',
	},
	{
		id: 564,
		value: 'Медынь',
	},
	{
		id: 565,
		value: 'Межгорье',
	},
	{
		id: 566,
		value: 'Междуреченск',
	},
	{
		id: 567,
		value: 'Мезень',
	},
	{
		id: 568,
		value: 'Меленки',
	},
	{
		id: 569,
		value: 'Мелеуз',
	},
	{
		id: 570,
		value: 'Менделеевск',
	},
	{
		id: 571,
		value: 'Мензелинск',
	},
	{
		id: 572,
		value: 'Мещовск',
	},
	{
		id: 573,
		value: 'Миасс',
	},
	{
		id: 574,
		value: 'Микунь',
	},
	{
		id: 575,
		value: 'Миллерово',
	},
	{
		id: 576,
		value: 'Минеральные Воды',
	},
	{
		id: 577,
		value: 'Минусинск',
	},
	{
		id: 578,
		value: 'Миньяр',
	},
	{
		id: 579,
		value: 'Мирный',
	},
	{
		id: 580,
		value: 'Михайлов',
	},
	{
		id: 581,
		value: 'Михайловка',
	},
	{
		id: 582,
		value: 'Михайловск',
	},
	{
		id: 583,
		value: 'Мичуринск',
	},
	{
		id: 584,
		value: 'Могоча',
	},
	{
		id: 585,
		value: 'Можайск',
	},
	{
		id: 586,
		value: 'Можга',
	},
	{
		id: 587,
		value: 'Моздок',
	},
	{
		id: 588,
		value: 'Мончегорск',
	},
	{
		id: 589,
		value: 'Морозовск',
	},
	{
		id: 590,
		value: 'Моршанск',
	},
	{
		id: 591,
		value: 'Мосальск',
	},
	{
		id: 592,
		value: 'Москва',
	},
	{
		id: 593,
		value: 'Муравленко',
	},
	{
		id: 594,
		value: 'Мураши',
	},
	{
		id: 595,
		value: 'Мурино',
	},
	{
		id: 596,
		value: 'Мурманск',
	},
	{
		id: 597,
		value: 'Муром',
	},
	{
		id: 598,
		value: 'Мценск',
	},
	{
		id: 599,
		value: 'Мыски',
	},
	{
		id: 600,
		value: 'Мытищи',
	},
	{
		id: 601,
		value: 'Мышкин',
	},
	{
		id: 602,
		value: 'Набережные Челны',
	},
	{
		id: 603,
		value: 'Навашино',
	},
	{
		id: 604,
		value: 'Наволоки',
	},
	{
		id: 605,
		value: 'Надым',
	},
	{
		id: 606,
		value: 'Назарово',
	},
	{
		id: 607,
		value: 'Назрань',
	},
	{
		id: 608,
		value: 'Называевск',
	},
	{
		id: 609,
		value: 'Нальчик',
	},
	{
		id: 610,
		value: 'Нариманов',
	},
	{
		id: 611,
		value: 'Наро-Фоминск',
	},
	{
		id: 612,
		value: 'Нарткала',
	},
	{
		id: 613,
		value: 'Нарьян-Мар',
	},
	{
		id: 614,
		value: 'Находка',
	},
	{
		id: 615,
		value: 'Невель',
	},
	{
		id: 616,
		value: 'Невельск',
	},
	{
		id: 617,
		value: 'Невинномысск',
	},
	{
		id: 618,
		value: 'Невьянск',
	},
	{
		id: 619,
		value: 'Нелидово',
	},
	{
		id: 620,
		value: 'Неман',
	},
	{
		id: 621,
		value: 'Нерехта',
	},
	{
		id: 622,
		value: 'Нерчинск',
	},
	{
		id: 623,
		value: 'Нерюнгри',
	},
	{
		id: 624,
		value: 'Нестеров',
	},
	{
		id: 625,
		value: 'Нефтегорск',
	},
	{
		id: 626,
		value: 'Нефтекамск',
	},
	{
		id: 627,
		value: 'Нефтекумск',
	},
	{
		id: 628,
		value: 'Нефтеюганск',
	},
	{
		id: 629,
		value: 'Нея',
	},
	{
		id: 630,
		value: 'Нижневартовск',
	},
	{
		id: 631,
		value: 'Нижнекамск',
	},
	{
		id: 632,
		value: 'Нижнеудинск',
	},
	{
		id: 633,
		value: 'Нижние Серги',
	},
	{
		id: 634,
		value: 'Нижний Ломов',
	},
	{
		id: 635,
		value: 'Нижний Новгород',
	},
	{
		id: 636,
		value: 'Нижний Тагил',
	},
	{
		id: 637,
		value: 'Нижняя Салда',
	},
	{
		id: 638,
		value: 'Нижняя Тура',
	},
	{
		id: 639,
		value: 'Николаевск-на-Амуре',
	},
	{
		id: 640,
		value: 'Николаевск',
	},
	{
		id: 641,
		value: 'Никольск',
	},
	{
		id: 642,
		value: 'Никольское',
	},
	{
		id: 643,
		value: 'Новая Ладога',
	},
	{
		id: 644,
		value: 'Новая Ляля',
	},
	{
		id: 645,
		value: 'Новоалександровск',
	},
	{
		id: 646,
		value: 'Новоалтайск',
	},
	{
		id: 647,
		value: 'Новоаннинский',
	},
	{
		id: 648,
		value: 'Нововоронеж',
	},
	{
		id: 649,
		value: 'Новодвинск',
	},
	{
		id: 650,
		value: 'Новозыбков',
	},
	{
		id: 651,
		value: 'Новокубанск',
	},
	{
		id: 652,
		value: 'Новокузнецк',
	},
	{
		id: 653,
		value: 'Новокуйбышевск',
	},
	{
		id: 654,
		value: 'Новомичуринск',
	},
	{
		id: 655,
		value: 'Новомосковск',
	},
	{
		id: 656,
		value: 'Новопавловск',
	},
	{
		id: 657,
		value: 'Новоржев',
	},
	{
		id: 658,
		value: 'Новороссийск',
	},
	{
		id: 659,
		value: 'Новосибирск',
	},
	{
		id: 660,
		value: 'Новосиль',
	},
	{
		id: 661,
		value: 'Новосокольники',
	},
	{
		id: 662,
		value: 'Новотроицк',
	},
	{
		id: 663,
		value: 'Новоузенск',
	},
	{
		id: 664,
		value: 'Новоульяновск',
	},
	{
		id: 665,
		value: 'Новоуральск',
	},
	{
		id: 666,
		value: 'Новохопёрск',
	},
	{
		id: 667,
		value: 'Новочебоксарск',
	},
	{
		id: 668,
		value: 'Новочеркасск',
	},
	{
		id: 669,
		value: 'Новошахтинск',
	},
	{
		id: 670,
		value: 'Новый Оскол',
	},
	{
		id: 671,
		value: 'Новый Уренгой',
	},
	{
		id: 672,
		value: 'Ногинск',
	},
	{
		id: 673,
		value: 'Нолинск',
	},
	{
		id: 674,
		value: 'Норильск',
	},
	{
		id: 675,
		value: 'Ноябрьск',
	},
	{
		id: 676,
		value: 'Нурлат',
	},
	{
		id: 677,
		value: 'Нытва',
	},
	{
		id: 678,
		value: 'Нюрба',
	},
	{
		id: 679,
		value: 'Нягань',
	},
	{
		id: 680,
		value: 'Нязепетровск',
	},
	{
		id: 681,
		value: 'Няндома',
	},
	{
		id: 682,
		value: 'Облучье',
	},
	{
		id: 683,
		value: 'Обнинск',
	},
	{
		id: 684,
		value: 'Обоянь',
	},
	{
		id: 685,
		value: 'Обь',
	},
	{
		id: 686,
		value: 'Одинцово',
	},
	{
		id: 687,
		value: 'Озёрск',
	},
	{
		id: 688,
		value: 'Озёры',
	},
	{
		id: 689,
		value: 'Октябрьск',
	},
	{
		id: 690,
		value: 'Октябрьский',
	},
	{
		id: 691,
		value: 'Окуловка',
	},
	{
		id: 692,
		value: 'Оленегорск',
	},
	{
		id: 693,
		value: 'Олонец',
	},
	{
		id: 694,
		value: 'Олёкминск',
	},
	{
		id: 695,
		value: 'Омск',
	},
	{
		id: 696,
		value: 'Омутнинск',
	},
	{
		id: 697,
		value: 'Онега',
	},
	{
		id: 698,
		value: 'Опочка',
	},
	{
		id: 699,
		value: 'Оренбург',
	},
	{
		id: 700,
		value: 'Орехово-Зуево',
	},
	{
		id: 701,
		value: 'Орлов',
	},
	{
		id: 702,
		value: 'Орск',
	},
	{
		id: 703,
		value: 'Орёл',
	},
	{
		id: 704,
		value: 'Оса',
	},
	{
		id: 705,
		value: 'Осинники',
	},
	{
		id: 706,
		value: 'Осташков',
	},
	{
		id: 707,
		value: 'Остров',
	},
	{
		id: 708,
		value: 'Островной',
	},
	{
		id: 709,
		value: 'Острогожск',
	},
	{
		id: 710,
		value: 'Отрадное',
	},
	{
		id: 711,
		value: 'Отрадный',
	},
	{
		id: 712,
		value: 'Оха',
	},
	{
		id: 713,
		value: 'Оханск',
	},
	{
		id: 714,
		value: 'Очёр',
	},
	{
		id: 715,
		value: 'Павлово',
	},
	{
		id: 716,
		value: 'Павловск',
	},
	{
		id: 717,
		value: 'Павловский Посад',
	},
	{
		id: 718,
		value: 'Палласовка',
	},
	{
		id: 719,
		value: 'Партизанск',
	},
	{
		id: 720,
		value: 'Певек',
	},
	{
		id: 721,
		value: 'Пенза',
	},
	{
		id: 722,
		value: 'Первомайск',
	},
	{
		id: 723,
		value: 'Первоуральск',
	},
	{
		id: 724,
		value: 'Перевоз',
	},
	{
		id: 725,
		value: 'Пересвет',
	},
	{
		id: 726,
		value: 'Переславль-Залесский',
	},
	{
		id: 727,
		value: 'Пермь',
	},
	{
		id: 728,
		value: 'Пестово',
	},
	{
		id: 729,
		value: 'Петров Вал',
	},
	{
		id: 730,
		value: 'Петровск-Забайкальский',
	},
	{
		id: 731,
		value: 'Петровск',
	},
	{
		id: 732,
		value: 'Петрозаводск',
	},
	{
		id: 733,
		value: 'Петропавловск-Камчатский',
	},
	{
		id: 734,
		value: 'Петухово',
	},
	{
		id: 735,
		value: 'Петушки',
	},
	{
		id: 736,
		value: 'Печора',
	},
	{
		id: 737,
		value: 'Печоры',
	},
	{
		id: 738,
		value: 'Пикалёво',
	},
	{
		id: 739,
		value: 'Пионерский',
	},
	{
		id: 740,
		value: 'Питкяранта',
	},
	{
		id: 741,
		value: 'Плавск',
	},
	{
		id: 742,
		value: 'Пласт',
	},
	{
		id: 743,
		value: 'Плёс',
	},
	{
		id: 744,
		value: 'Поворино',
	},
	{
		id: 745,
		value: 'Подольск',
	},
	{
		id: 746,
		value: 'Подпорожье',
	},
	{
		id: 747,
		value: 'Покачи',
	},
	{
		id: 748,
		value: 'Покров',
	},
	{
		id: 749,
		value: 'Покровск',
	},
	{
		id: 750,
		value: 'Полевской',
	},
	{
		id: 751,
		value: 'Полесск',
	},
	{
		id: 752,
		value: 'Полысаево',
	},
	{
		id: 753,
		value: 'Полярные Зори',
	},
	{
		id: 754,
		value: 'Полярный',
	},
	{
		id: 755,
		value: 'Поронайск',
	},
	{
		id: 756,
		value: 'Порхов',
	},
	{
		id: 757,
		value: 'Похвистнево',
	},
	{
		id: 758,
		value: 'Почеп',
	},
	{
		id: 759,
		value: 'Починок',
	},
	{
		id: 760,
		value: 'Пошехонье',
	},
	{
		id: 761,
		value: 'Правдинск',
	},
	{
		id: 762,
		value: 'Приволжск',
	},
	{
		id: 763,
		value: 'Приморск',
	},
	{
		id: 764,
		value: 'Приморско-Ахтарск',
	},
	{
		id: 765,
		value: 'Приозерск',
	},
	{
		id: 766,
		value: 'Прокопьевск',
	},
	{
		id: 767,
		value: 'Пролетарск',
	},
	{
		id: 768,
		value: 'Протвино',
	},
	{
		id: 769,
		value: 'Прохладный',
	},
	{
		id: 770,
		value: 'Псков',
	},
	{
		id: 771,
		value: 'Пугачёв',
	},
	{
		id: 772,
		value: 'Пудож',
	},
	{
		id: 773,
		value: 'Пустошка',
	},
	{
		id: 774,
		value: 'Пучеж',
	},
	{
		id: 775,
		value: 'Пушкино',
	},
	{
		id: 776,
		value: 'Пущино',
	},
	{
		id: 777,
		value: 'Пыталово',
	},
	{
		id: 778,
		value: 'Пыть-Ях',
	},
	{
		id: 779,
		value: 'Пятигорск',
	},
	{
		id: 780,
		value: 'Радужный',
	},
	{
		id: 781,
		value: 'Райчихинск',
	},
	{
		id: 782,
		value: 'Раменское',
	},
	{
		id: 783,
		value: 'Рассказово',
	},
	{
		id: 784,
		value: 'Ревда',
	},
	{
		id: 785,
		value: 'Реж',
	},
	{
		id: 786,
		value: 'Реутов',
	},
	{
		id: 787,
		value: 'Ржев',
	},
	{
		id: 788,
		value: 'Родники',
	},
	{
		id: 789,
		value: 'Рославль',
	},
	{
		id: 790,
		value: 'Россошь',
	},
	{
		id: 791,
		value: 'Ростов-на-Дону',
	},
	{
		id: 792,
		value: 'Ростов',
	},
	{
		id: 793,
		value: 'Рошаль',
	},
	{
		id: 794,
		value: 'Ртищево',
	},
	{
		id: 795,
		value: 'Рубцовск',
	},
	{
		id: 796,
		value: 'Рудня',
	},
	{
		id: 797,
		value: 'Руза',
	},
	{
		id: 798,
		value: 'Рузаевка',
	},
	{
		id: 799,
		value: 'Рыбинск',
	},
	{
		id: 800,
		value: 'Рыбное',
	},
	{
		id: 801,
		value: 'Рыльск',
	},
	{
		id: 802,
		value: 'Ряжск',
	},
	{
		id: 803,
		value: 'Рязань',
	},
	{
		id: 804,
		value: 'Саки',
	},
	{
		id: 805,
		value: 'Салават',
	},
	{
		id: 806,
		value: 'Салаир',
	},
	{
		id: 807,
		value: 'Салехард',
	},
	{
		id: 808,
		value: 'Сальск',
	},
	{
		id: 809,
		value: 'Самара',
	},
	{
		id: 810,
		value: 'Санкт-Петербург',
	},
	{
		id: 811,
		value: 'Саранск',
	},
	{
		id: 812,
		value: 'Сарапул',
	},
	{
		id: 813,
		value: 'Саратов',
	},
	{
		id: 814,
		value: 'Саров',
	},
	{
		id: 815,
		value: 'Сасово',
	},
	{
		id: 816,
		value: 'Сатка',
	},
	{
		id: 817,
		value: 'Сафоново',
	},
	{
		id: 818,
		value: 'Саяногорск',
	},
	{
		id: 819,
		value: 'Саянск',
	},
	{
		id: 820,
		value: 'Светлогорск',
	},
	{
		id: 821,
		value: 'Светлоград',
	},
	{
		id: 822,
		value: 'Светлый',
	},
	{
		id: 823,
		value: 'Светогорск',
	},
	{
		id: 824,
		value: 'Свирск',
	},
	{
		id: 825,
		value: 'Свободный',
	},
	{
		id: 826,
		value: 'Себеж',
	},
	{
		id: 827,
		value: 'Севастополь',
	},
	{
		id: 828,
		value: 'Северо-Курильск',
	},
	{
		id: 829,
		value: 'Северобайкальск',
	},
	{
		id: 830,
		value: 'Северодвинск',
	},
	{
		id: 831,
		value: 'Североморск',
	},
	{
		id: 832,
		value: 'Североуральск',
	},
	{
		id: 833,
		value: 'Северск',
	},
	{
		id: 834,
		value: 'Севск',
	},
	{
		id: 835,
		value: 'Сегежа',
	},
	{
		id: 836,
		value: 'Сельцо',
	},
	{
		id: 837,
		value: 'Семикаракорск',
	},
	{
		id: 838,
		value: 'Семилуки',
	},
	{
		id: 839,
		value: 'Семёнов',
	},
	{
		id: 840,
		value: 'Сенгилей',
	},
	{
		id: 841,
		value: 'Серафимович',
	},
	{
		id: 842,
		value: 'Сергач',
	},
	{
		id: 843,
		value: 'Сергиев Посад',
	},
	{
		id: 844,
		value: 'Сердобск',
	},
	{
		id: 845,
		value: 'Серов',
	},
	{
		id: 846,
		value: 'Серпухов',
	},
	{
		id: 847,
		value: 'Сертолово',
	},
	{
		id: 848,
		value: 'Сибай',
	},
	{
		id: 849,
		value: 'Сим',
	},
	{
		id: 850,
		value: 'Симферополь',
	},
	{
		id: 851,
		value: 'Сковородино',
	},
	{
		id: 852,
		value: 'Скопин',
	},
	{
		id: 853,
		value: 'Славгород',
	},
	{
		id: 854,
		value: 'Славск',
	},
	{
		id: 855,
		value: 'Славянск-на-Кубани',
	},
	{
		id: 856,
		value: 'Сланцы',
	},
	{
		id: 857,
		value: 'Слободской',
	},
	{
		id: 858,
		value: 'Слюдянка',
	},
	{
		id: 859,
		value: 'Смоленск',
	},
	{
		id: 860,
		value: 'Снежинск',
	},
	{
		id: 861,
		value: 'Снежногорск',
	},
	{
		id: 862,
		value: 'Собинка',
	},
	{
		id: 863,
		value: 'Советск',
	},
	{
		id: 864,
		value: 'Советская Гавань',
	},
	{
		id: 865,
		value: 'Советский',
	},
	{
		id: 866,
		value: 'Сокол',
	},
	{
		id: 867,
		value: 'Солигалич',
	},
	{
		id: 868,
		value: 'Соликамск',
	},
	{
		id: 869,
		value: 'Солнечногорск',
	},
	{
		id: 870,
		value: 'Соль-Илецк',
	},
	{
		id: 871,
		value: 'Сольвычегодск',
	},
	{
		id: 872,
		value: 'Сольцы',
	},
	{
		id: 873,
		value: 'Сорочинск',
	},
	{
		id: 874,
		value: 'Сорск',
	},
	{
		id: 875,
		value: 'Сортавала',
	},
	{
		id: 876,
		value: 'Сосенский',
	},
	{
		id: 877,
		value: 'Сосновка',
	},
	{
		id: 878,
		value: 'Сосновоборск',
	},
	{
		id: 879,
		value: 'Сосновый Бор',
	},
	{
		id: 880,
		value: 'Сосногорск',
	},
	{
		id: 881,
		value: 'Сочи',
	},
	{
		id: 882,
		value: 'Спас-Деменск',
	},
	{
		id: 883,
		value: 'Спас-Клепики',
	},
	{
		id: 884,
		value: 'Спасск-Дальний',
	},
	{
		id: 885,
		value: 'Спасск-Рязанский',
	},
	{
		id: 886,
		value: 'Спасск',
	},
	{
		id: 887,
		value: 'Среднеколымск',
	},
	{
		id: 888,
		value: 'Среднеуральск',
	},
	{
		id: 889,
		value: 'Сретенск',
	},
	{
		id: 890,
		value: 'Ставрополь',
	},
	{
		id: 891,
		value: 'Старая Купавна',
	},
	{
		id: 892,
		value: 'Старая Русса',
	},
	{
		id: 893,
		value: 'Старица',
	},
	{
		id: 894,
		value: 'Стародуб',
	},
	{
		id: 895,
		value: 'Старый Крым',
	},
	{
		id: 896,
		value: 'Старый Оскол',
	},
	{
		id: 897,
		value: 'Стерлитамак',
	},
	{
		id: 898,
		value: 'Стрежевой',
	},
	{
		id: 899,
		value: 'Строитель',
	},
	{
		id: 900,
		value: 'Струнино',
	},
	{
		id: 901,
		value: 'Ступино',
	},
	{
		id: 902,
		value: 'Суворов',
	},
	{
		id: 903,
		value: 'Судак',
	},
	{
		id: 904,
		value: 'Суджа',
	},
	{
		id: 905,
		value: 'Судогда',
	},
	{
		id: 906,
		value: 'Суздаль',
	},
	{
		id: 907,
		value: 'Сунжа',
	},
	{
		id: 908,
		value: 'Суоярви',
	},
	{
		id: 909,
		value: 'Сураж',
	},
	{
		id: 910,
		value: 'Сургут',
	},
	{
		id: 911,
		value: 'Суровикино',
	},
	{
		id: 912,
		value: 'Сурск',
	},
	{
		id: 913,
		value: 'Сусуман',
	},
	{
		id: 914,
		value: 'Сухиничи',
	},
	{
		id: 915,
		value: 'Сухой Лог',
	},
	{
		id: 916,
		value: 'Сызрань',
	},
	{
		id: 917,
		value: 'Сыктывкар',
	},
	{
		id: 918,
		value: 'Сысерть',
	},
	{
		id: 919,
		value: 'Сычёвка',
	},
	{
		id: 920,
		value: 'Сясьстрой',
	},
	{
		id: 921,
		value: 'Тавда',
	},
	{
		id: 922,
		value: 'Таганрог',
	},
	{
		id: 923,
		value: 'Тайга',
	},
	{
		id: 924,
		value: 'Тайшет',
	},
	{
		id: 925,
		value: 'Талдом',
	},
	{
		id: 926,
		value: 'Талица',
	},
	{
		id: 927,
		value: 'Тамбов',
	},
	{
		id: 928,
		value: 'Тара',
	},
	{
		id: 929,
		value: 'Тарко-Сале',
	},
	{
		id: 930,
		value: 'Таруса',
	},
	{
		id: 931,
		value: 'Татарск',
	},
	{
		id: 932,
		value: 'Таштагол',
	},
	{
		id: 933,
		value: 'Тверь',
	},
	{
		id: 934,
		value: 'Теберда',
	},
	{
		id: 935,
		value: 'Тейково',
	},
	{
		id: 936,
		value: 'Темников',
	},
	{
		id: 937,
		value: 'Темрюк',
	},
	{
		id: 938,
		value: 'Терек',
	},
	{
		id: 939,
		value: 'Тетюши',
	},
	{
		id: 940,
		value: 'Тимашёвск',
	},
	{
		id: 941,
		value: 'Тихвин',
	},
	{
		id: 942,
		value: 'Тихорецк',
	},
	{
		id: 943,
		value: 'Тобольск',
	},
	{
		id: 944,
		value: 'Тогучин',
	},
	{
		id: 945,
		value: 'Тольятти',
	},
	{
		id: 946,
		value: 'Томари',
	},
	{
		id: 947,
		value: 'Томмот',
	},
	{
		id: 948,
		value: 'Томск',
	},
	{
		id: 949,
		value: 'Топки',
	},
	{
		id: 950,
		value: 'Торжок',
	},
	{
		id: 951,
		value: 'Торопец',
	},
	{
		id: 952,
		value: 'Тосно',
	},
	{
		id: 953,
		value: 'Тотьма',
	},
	{
		id: 954,
		value: 'Троицк',
	},
	{
		id: 955,
		value: 'Трубчевск',
	},
	{
		id: 956,
		value: 'Трёхгорный',
	},
	{
		id: 957,
		value: 'Туапсе',
	},
	{
		id: 958,
		value: 'Туймазы',
	},
	{
		id: 959,
		value: 'Тула',
	},
	{
		id: 960,
		value: 'Тулун',
	},
	{
		id: 961,
		value: 'Туран',
	},
	{
		id: 962,
		value: 'Туринск',
	},
	{
		id: 963,
		value: 'Тутаев',
	},
	{
		id: 964,
		value: 'Тында',
	},
	{
		id: 965,
		value: 'Тырныауз',
	},
	{
		id: 966,
		value: 'Тюкалинск',
	},
	{
		id: 967,
		value: 'Тюмень',
	},
	{
		id: 968,
		value: 'Уварово',
	},
	{
		id: 969,
		value: 'Углегорск',
	},
	{
		id: 970,
		value: 'Углич',
	},
	{
		id: 971,
		value: 'Удачный',
	},
	{
		id: 972,
		value: 'Удомля',
	},
	{
		id: 973,
		value: 'Ужур',
	},
	{
		id: 974,
		value: 'Узловая',
	},
	{
		id: 975,
		value: 'Улан-Удэ',
	},
	{
		id: 976,
		value: 'Ульяновск',
	},
	{
		id: 977,
		value: 'Унеча',
	},
	{
		id: 978,
		value: 'Урай',
	},
	{
		id: 979,
		value: 'Урень',
	},
	{
		id: 980,
		value: 'Уржум',
	},
	{
		id: 981,
		value: 'Урус-Мартан',
	},
	{
		id: 982,
		value: 'Урюпинск',
	},
	{
		id: 983,
		value: 'Усинск',
	},
	{
		id: 984,
		value: 'Усмань',
	},
	{
		id: 985,
		value: 'Усолье-Сибирское',
	},
	{
		id: 986,
		value: 'Усолье',
	},
	{
		id: 987,
		value: 'Уссурийск',
	},
	{
		id: 988,
		value: 'Усть-Джегута',
	},
	{
		id: 989,
		value: 'Усть-Илимск',
	},
	{
		id: 990,
		value: 'Усть-Катав',
	},
	{
		id: 991,
		value: 'Усть-Кут',
	},
	{
		id: 992,
		value: 'Усть-Лабинск',
	},
	{
		id: 993,
		value: 'Устюжна',
	},
	{
		id: 994,
		value: 'Уфа',
	},
	{
		id: 995,
		value: 'Ухта',
	},
	{
		id: 996,
		value: 'Учалы',
	},
	{
		id: 997,
		value: 'Уяр',
	},
	{
		id: 998,
		value: 'Фатеж',
	},
	{
		id: 999,
		value: 'Феодосия',
	},
	{
		id: 1000,
		value: 'Фокино',
	},
	{
		id: 1001,
		value: 'Фролово',
	},
	{
		id: 1002,
		value: 'Фрязино',
	},
	{
		id: 1003,
		value: 'Фурманов',
	},
	{
		id: 1004,
		value: 'Хабаровск',
	},
	{
		id: 1005,
		value: 'Хадыженск',
	},
	{
		id: 1006,
		value: 'Ханты-Мансийск',
	},
	{
		id: 1007,
		value: 'Харабали',
	},
	{
		id: 1008,
		value: 'Харовск',
	},
	{
		id: 1009,
		value: 'Хасавюрт',
	},
	{
		id: 1010,
		value: 'Хвалынск',
	},
	{
		id: 1011,
		value: 'Хилок',
	},
	{
		id: 1012,
		value: 'Химки',
	},
	{
		id: 1013,
		value: 'Холм',
	},
	{
		id: 1014,
		value: 'Холмск',
	},
	{
		id: 1015,
		value: 'Хотьково',
	},
	{
		id: 1016,
		value: 'Цивильск',
	},
	{
		id: 1017,
		value: 'Цимлянск',
	},
	{
		id: 1018,
		value: 'Циолковский',
	},
	{
		id: 1019,
		value: 'Чадан',
	},
	{
		id: 1020,
		value: 'Чайковский',
	},
	{
		id: 1021,
		value: 'Чапаевск',
	},
	{
		id: 1022,
		value: 'Чаплыгин',
	},
	{
		id: 1023,
		value: 'Чебаркуль',
	},
	{
		id: 1024,
		value: 'Чебоксары',
	},
	{
		id: 1025,
		value: 'Чегем',
	},
	{
		id: 1026,
		value: 'Чекалин',
	},
	{
		id: 1027,
		value: 'Челябинск',
	},
	{
		id: 1028,
		value: 'Чердынь',
	},
	{
		id: 1029,
		value: 'Черемхово',
	},
	{
		id: 1030,
		value: 'Черепаново',
	},
	{
		id: 1031,
		value: 'Череповец',
	},
	{
		id: 1032,
		value: 'Черкесск',
	},
	{
		id: 1033,
		value: 'Черноголовка',
	},
	{
		id: 1034,
		value: 'Черногорск',
	},
	{
		id: 1035,
		value: 'Чернушка',
	},
	{
		id: 1036,
		value: 'Черняховск',
	},
	{
		id: 1037,
		value: 'Чехов',
	},
	{
		id: 1038,
		value: 'Чистополь',
	},
	{
		id: 1039,
		value: 'Чита',
	},
	{
		id: 1040,
		value: 'Чкаловск',
	},
	{
		id: 1041,
		value: 'Чудово',
	},
	{
		id: 1042,
		value: 'Чулым',
	},
	{
		id: 1043,
		value: 'Чусовой',
	},
	{
		id: 1044,
		value: 'Чухлома',
	},
	{
		id: 1045,
		value: 'Чёрмоз',
	},
	{
		id: 1046,
		value: 'Шагонар',
	},
	{
		id: 1047,
		value: 'Шадринск',
	},
	{
		id: 1048,
		value: 'Шали',
	},
	{
		id: 1049,
		value: 'Шарыпово',
	},
	{
		id: 1050,
		value: 'Шарья',
	},
	{
		id: 1051,
		value: 'Шатура',
	},
	{
		id: 1052,
		value: 'Шахты',
	},
	{
		id: 1053,
		value: 'Шахунья',
	},
	{
		id: 1054,
		value: 'Шацк',
	},
	{
		id: 1055,
		value: 'Шебекино',
	},
	{
		id: 1056,
		value: 'Шелехов',
	},
	{
		id: 1057,
		value: 'Шенкурск',
	},
	{
		id: 1058,
		value: 'Шилка',
	},
	{
		id: 1059,
		value: 'Шимановск',
	},
	{
		id: 1060,
		value: 'Шиханы',
	},
	{
		id: 1061,
		value: 'Шлиссельбург',
	},
	{
		id: 1062,
		value: 'Шумерля',
	},
	{
		id: 1063,
		value: 'Шумиха',
	},
	{
		id: 1064,
		value: 'Шуя',
	},
	{
		id: 1065,
		value: 'Щигры',
	},
	{
		id: 1066,
		value: 'Щучье',
	},
	{
		id: 1067,
		value: 'Щёкино',
	},
	{
		id: 1068,
		value: 'Щёлкино',
	},
	{
		id: 1069,
		value: 'Щёлково',
	},
	{
		id: 1070,
		value: 'Электрогорск',
	},
	{
		id: 1071,
		value: 'Электросталь',
	},
	{
		id: 1072,
		value: 'Электроугли',
	},
	{
		id: 1073,
		value: 'Элиста',
	},
	{
		id: 1074,
		value: 'Энгельс',
	},
	{
		id: 1075,
		value: 'Эртиль',
	},
	{
		id: 1076,
		value: 'Югорск',
	},
	{
		id: 1077,
		value: 'Южа',
	},
	{
		id: 1078,
		value: 'Южно-Сахалинск',
	},
	{
		id: 1079,
		value: 'Южно-Сухокумск',
	},
	{
		id: 1080,
		value: 'Южноуральск',
	},
	{
		id: 1081,
		value: 'Юрга',
	},
	{
		id: 1082,
		value: 'Юрьев-Польский',
	},
	{
		id: 1083,
		value: 'Юрьевец',
	},
	{
		id: 1084,
		value: 'Юрюзань',
	},
	{
		id: 1085,
		value: 'Юхнов',
	},
	{
		id: 1086,
		value: 'Ядрин',
	},
	{
		id: 1087,
		value: 'Якутск',
	},
	{
		id: 1088,
		value: 'Ялта',
	},
	{
		id: 1089,
		value: 'Ялуторовск',
	},
	{
		id: 1090,
		value: 'Янаул',
	},
	{
		id: 1091,
		value: 'Яранск',
	},
	{
		id: 1092,
		value: 'Яровое',
	},
	{
		id: 1093,
		value: 'Ярославль',
	},
	{
		id: 1094,
		value: 'Ярцево',
	},
	{
		id: 1095,
		value: 'Ясногорск',
	},
	{
		id: 1096,
		value: 'Ясный',
	},
	{
		id: 1097,
		value: 'Яхрома',
	},
	{
		id: 1098,
		value: 'Брест',
	},
];

const kazahstanCities = [
	{
		id: '6251',
		value: 'Абай',
	},
	{
		id: '6782',
		value: 'Айет',
	},
	{
		id: '11071',
		value: 'Айтеке-Би',
	},
	{
		id: '11083',
		value: 'Аккистау',
	},
	{
		id: '2728',
		value: 'Акколь',
	},
	{
		id: '150',
		value: 'Аксай (Казахстан)',
	},
	{
		id: '6254',
		value: 'Аксукент',
	},
	{
		id: '151',
		value: 'Аксу (Павлодар.обл)',
	},
	{
		id: '152',
		value: 'Актау',
	},
	{
		id: '154',
		value: 'Актобе',
	},
	{
		id: '6365',
		value: 'Актогай',
	},
	{
		id: '156',
		value: 'Алга (Актюбинская обл)',
	},
	{
		id: '160',
		value: 'Алматы',
	},
	{
		id: '169',
		value: 'Алтай (Зыряновск)',
	},
	{
		id: '158',
		value: 'Аральск',
	},
	{
		id: '161',
		value: 'Аркалык',
	},
	{
		id: '6300',
		value: 'Арысь (Туркестанская область)',
	},
	{
		id: '155',
		value: 'Арысь (ЮКО)',
	},
	{
		id: '6493',
		value: 'Аса',
	},
	{
		id: '159',
		value: 'Астана',
	},
	{
		id: '6529',
		value: 'Астраханка',
	},
	{
		id: '6511',
		value: 'Асыката',
	},
	{
		id: '6510',
		value: 'Атакент',
	},
	{
		id: '6351',
		value: 'Атасу',
	},
	{
		id: '3663',
		value: 'Атбасар',
	},
	{
		id: '153',
		value: 'Атырау',
	},
	{
		id: '5053',
		value: 'Аулиеколь',
	},
	{
		id: '157',
		value: 'Аягоз (ВКО)',
	},
	{
		id: '2226',
		value: 'Байконур (Кызылорд. обл)',
	},
	{
		id: '6497',
		value: 'Балпык-Би',
	},
	{
		id: '164',
		value: 'Балхаш',
	},
	{
		id: '5118',
		value: 'Батпак',
	},
	{
		id: '162',
		value: 'Баутино',
	},
	{
		id: '6488',
		value: 'Бауыржан Момышулы',
	},
	{
		id: '163',
		value: 'Бейнеу (Мангистауская обл)',
	},
	{
		id: '5055',
		value: 'Бесколь',
	},
	{
		id: '6778',
		value: 'Боровской',
	},
	{
		id: '5129',
		value: 'Бурабай',
	},
	{
		id: '6431',
		value: 'Глубокое (Восточно-Казахстанская область)',
	},
	{
		id: '6483',
		value: 'Дарьинское',
	},
	{
		id: '11076',
		value: 'Державинск',
	},
	{
		id: '165',
		value: 'Ерейментау (Акмол.обл)',
	},
	{
		id: '6287',
		value: 'Есик',
	},
	{
		id: '6292',
		value: 'Есиль',
	},
	{
		id: '6343',
		value: 'Жайрем',
	},
	{
		id: '5124',
		value: 'Жаксы',
	},
	{
		id: '6347',
		value: 'Жалагаш',
	},
	{
		id: '6446',
		value: 'Жанакорган',
	},
	{
		id: '2510',
		value: 'Жанаозен',
	},
	{
		id: '167',
		value: 'Жанатас',
	},
	{
		id: '6482',
		value: 'Жангала',
	},
	{
		id: '6481',
		value: 'Жанибек',
	},
	{
		id: '6496',
		value: 'Жансугуров',
	},
	{
		id: '6104',
		value: 'Жаркент',
	},
	{
		id: '166',
		value: 'Жезказган',
	},
	{
		id: '6989',
		value: 'Жезкент',
	},
	{
		id: '6947',
		value: 'Жем',
	},
	{
		id: '6525',
		value: 'Жетыбай (Мангистауская область)',
	},
	{
		id: '2952',
		value: 'Жетысай',
	},
	{
		id: '11163',
		value: 'Калбатау',
	},
	{
		id: '6322',
		value: 'Житикара',
	},
	{
		id: '6346',
		value: 'Жосалы (Кызылординская область)',
	},
	{
		id: '6479',
		value: 'Жымпиты',
	},
	{
		id: '168',
		value: 'Зайсан',
	},
	{
		id: '170',
		value: 'Иссык',
	},
	{
		id: '6460',
		value: 'Кабанбай',
	},
	{
		id: '175',
		value: 'Казалы (Кызылорд. обл)',
	},
	{
		id: '6509',
		value: 'Казыгурт',
	},
	{
		id: '3074',
		value: 'Кандыагаш',
	},
	{
		id: '5054',
		value: 'Карабалык',
	},
	{
		id: '6358',
		value: 'Карабулак (Алматинская область)',
	},
	{
		id: '11072',
		value: 'Карабулак (Сайрамский район)',
	},
	{
		id: '177',
		value: 'Караганда',
	},
	{
		id: '6471',
		value: 'Каражал',
	},
	{
		id: '178',
		value: 'Каратау',
	},
	{
		id: '6478',
		value: 'Каратобе',
	},
	{
		id: '11078',
		value: 'Карауылкельды',
	},
	{
		id: '6342',
		value: 'Каркаралинск',
	},
	{
		id: '2653',
		value: 'Каскелен',
	},
	{
		id: '5052',
		value: 'Качар',
	},
	{
		id: '6495',
		value: 'Кеген',
	},
	{
		id: '173',
		value: 'Кентау (ЮКО)',
	},
	{
		id: '6513',
		value: 'Кокпекты',
	},
	{
		id: '176',
		value: 'Кокшетау',
	},
	{
		id: '171',
		value: 'Конаев',
	},
	{
		id: '4491',
		value: 'Кордай',
	},
	{
		id: '172',
		value: 'Костанай',
	},
	{
		id: '6393',
		value: 'Косшы',
	},
	{
		id: '6487',
		value: 'Кулан',
	},
	{
		id: '3034',
		value: 'Кульсары',
	},
	{
		id: '5051',
		value: 'Курчатов (Казахстан)',
	},
	{
		id: '11162',
		value: 'Курык',
	},
	{
		id: '6780',
		value: 'Кушмурун',
	},
	{
		id: '174',
		value: 'Кызылорда',
	},
	{
		id: '179',
		value: 'Ленгер (ЮКО)',
	},
	{
		id: '6297',
		value: 'Лисаковск',
	},
	{
		id: '6512',
		value: 'Маканчи',
	},
	{
		id: '2950',
		value: 'Макинск',
	},
	{
		id: '6244',
		value: 'Мангистау',
	},
	{
		id: '6486',
		value: 'Мерке',
	},
	{
		id: '6485',
		value: 'Мойынкум',
	},
	{
		id: '6503',
		value: 'Мырзакент',
	},
	{
		id: '6494',
		value: 'Нарынкол',
	},
	{
		id: '11082',
		value: 'Новая Бухтарма',
	},
	{
		id: '5056',
		value: 'Новоишимское',
	},
	{
		id: '5117',
		value: 'Осакаровка',
	},
	{
		id: '6299',
		value: 'Отеген-Батыр',
	},
	{
		id: '181',
		value: 'Павлодар',
	},
	{
		id: '6477',
		value: 'Перемётное',
	},
	{
		id: '180',
		value: 'Петропавловск',
	},
	{
		id: '6395',
		value: 'Приозёрск (Карагандинская область)',
	},
	{
		id: '183',
		value: 'Риддер (ВКО)',
	},
	{
		id: '182',
		value: 'Рудный',
	},
	{
		id: '6501',
		value: 'Рыскулово',
	},
	{
		id: '6502',
		value: 'Сайрам',
	},
	{
		id: '6476',
		value: 'Сайхин',
	},
	{
		id: '5126',
		value: 'Саксаульский',
	},
	{
		id: '6017',
		value: 'Сарань',
	},
	{
		id: '4304',
		value: 'Сарканд',
	},
	{
		id: '2951',
		value: 'Сарыагаш',
	},
	{
		id: '6484',
		value: 'Сарыкемер',
	},
	{
		id: '6779',
		value: 'Сарыколь',
	},
	{
		id: '6459',
		value: 'Сарыозек',
	},
	{
		id: '184',
		value: 'Сатпаев',
	},
	{
		id: '11073',
		value: 'Село имени Турара Рыскулова',
	},
	{
		id: '185',
		value: 'Семей',
	},
	{
		id: '6060',
		value: 'Сергеевка (Северо-Казахстанская область)',
	},
	{
		id: '2755',
		value: 'Серебрянск',
	},
	{
		id: '6480',
		value: 'Солнечное (Глубоковский район)',
	},
	{
		id: '186',
		value: 'Солнечный (ПГТ, Павлодарская обл)',
	},
	{
		id: '6186',
		value: 'Софиевка (Казахстан)',
	},
	{
		id: '2437',
		value: 'Степногорск',
	},
	{
		id: '5164',
		value: 'Степняк',
	},
	{
		id: '6475',
		value: 'Тайпак',
	},
	{
		id: '5042',
		value: 'Тайынша',
	},
	{
		id: '3048',
		value: 'Талгар',
	},
	{
		id: '188',
		value: 'Талдыкорган',
	},
	{
		id: '6325',
		value: 'Талшик',
	},
	{
		id: '187',
		value: 'Тараз',
	},
	{
		id: '6474',
		value: 'Таскала',
	},
	{
		id: '7003',
		value: 'Тасты-Талды',
	},
	{
		id: '189',
		value: 'Текели',
	},
	{
		id: '190',
		value: 'Темиртау',
	},
	{
		id: '191',
		value: 'Торгай',
	},
	{
		id: '6344',
		value: 'Торетам',
	},
	{
		id: '6366',
		value: 'Торткудук',
	},
	{
		id: '192',
		value: 'Туркестан',
	},
	{
		id: '6248',
		value: 'Узынагаш',
	},
	{
		id: '6935',
		value: 'Улкен-Нарын',
	},
	{
		id: '193',
		value: 'Уральск',
	},
	{
		id: '6453',
		value: 'Урджар',
	},
	{
		id: '194',
		value: 'Усть-Каменогорск',
	},
	{
		id: '6349',
		value: 'Ушарал',
	},
	{
		id: '6250',
		value: 'Уштобе',
	},
	{
		id: '6781',
		value: 'Фёдоровка',
	},
	{
		id: '195',
		value: 'Хромтау',
	},
	{
		id: '6473',
		value: 'Чапаево',
	},
	{
		id: '3704',
		value: 'Чарск (Шар)',
	},
	{
		id: '6472',
		value: 'Чингирлау',
	},
	{
		id: '4637',
		value: 'Чунджа',
	},
	{
		id: '6348',
		value: 'Шалкар',
	},
	{
		id: '6388',
		value: 'Шардара',
	},
	{
		id: '6500',
		value: 'Шаульдер',
	},
	{
		id: '6290',
		value: 'Шахтинск',
	},
	{
		id: '6499',
		value: 'Шаян',
	},
	{
		id: '6108',
		value: 'Шелек',
	},
	{
		id: '196',
		value: 'Шемонаиха (ВКО)',
	},
	{
		id: '6524',
		value: 'Шетпе',
	},
	{
		id: '6324',
		value: 'Шиели',
	},
	{
		id: '6498',
		value: 'Шолаккорган',
	},
	{
		id: '2729',
		value: 'Шу',
	},
	{
		id: '205',
		value: 'Шымкент',
	},
	{
		id: '197',
		value: 'Щучинск',
	},
	{
		id: '198',
		value: 'Экибастуз',
	},
	{
		id: '6948',
		value: 'Эмба',
	},
];

const azerbCities = [
	{
		id: '2970',
		value: 'Агдаш',
	},
	{
		id: '2966',
		value: 'Агджабеди',
	},
	{
		id: '2626',
		value: 'Агсу',
	},
	{
		id: '2972',
		value: 'Аджигабул',
	},
	{
		id: '2988',
		value: 'Акстафа',
	},
	{
		id: '2982',
		value: 'Астара',
	},
	{
		id: '2492',
		value: 'Баку',
	},
	{
		id: '2625',
		value: 'Барда',
	},
	{
		id: '2984',
		value: 'Бейлаган',
	},
	{
		id: '2977',
		value: 'Билясувар',
	},
	{
		id: '2493',
		value: 'Габала',
	},
	{
		id: '2624',
		value: 'Газах',
	},
	{
		id: '2623',
		value: 'Геранбой',
	},
	{
		id: '2980',
		value: 'Гёйгёль',
	},
	{
		id: '2985',
		value: 'Гёйтепе',
	},
	{
		id: '2627',
		value: 'Гёйчай',
	},
	{
		id: '2494',
		value: 'Гянджа',
	},
	{
		id: '2991',
		value: 'Дашкесан',
	},
	{
		id: '2965',
		value: 'Джалилабад',
	},
	{
		id: '2989',
		value: 'Джульфа',
	},
	{
		id: '2816',
		value: 'Евлах',
	},
	{
		id: '2496',
		value: 'Закаталы',
	},
	{
		id: '2993',
		value: 'Зардоб',
	},
	{
		id: '2969',
		value: 'Имишли',
	},
	{
		id: '2621',
		value: 'Исмаиллы',
	},
	{
		id: '2975',
		value: 'Казах',
	},
	{
		id: '2987',
		value: 'Кахи',
	},
	{
		id: '2992',
		value: 'Кедабек',
	},
	{
		id: '2497',
		value: 'Куба (Азербайджан)',
	},
	{
		id: '2644',
		value: 'Кусары',
	},
	{
		id: '2978',
		value: 'Кюрдамир',
	},
	{
		id: '2498',
		value: 'Ленкорань',
	},
	{
		id: '2990',
		value: 'Лиман',
	},
	{
		id: '2499',
		value: 'Мингечаур',
	},
	{
		id: '2500',
		value: 'Нахичевань',
	},
	{
		id: '2976',
		value: 'Нефтечала',
	},
	{
		id: '2501',
		value: 'Ордубад',
	},
	{
		id: '2979',
		value: 'Саатлы',
	},
	{
		id: '2971',
		value: 'Сабирабад',
	},
	{
		id: '2968',
		value: 'Сальяны',
	},
	{
		id: '2973',
		value: 'Сиазань',
	},
	{
		id: '2495',
		value: 'Сумгаит',
	},
	{
		id: '2986',
		value: 'Тауз',
	},
	{
		id: '2622',
		value: 'Тертер',
	},
	{
		id: '2981',
		value: 'Уджары',
	},
	{
		id: '2807',
		value: 'Хачмаз',
	},
	{
		id: '2983',
		value: 'Худат',
	},
	{
		id: '2963',
		value: 'Хырдалан',
	},
	{
		id: '2974',
		value: 'Шабран',
	},
	{
		id: '2967',
		value: 'Шамкир',
	},
	{
		id: '2502',
		value: 'Шеки',
	},
	{
		id: '2503',
		value: 'Шемаха',
	},
	{
		id: '2964',
		value: 'Ширван',
	},
];

const belarusCities = [
	{
		id: '1007',
		value: 'Брест',
	},
	{
		id: '11130',
		value: 'Антополь',
	},
	{
		id: '2239',
		value: 'Барановичи',
	},
	{
		id: '2630',
		value: 'Белоозерск',
	},
	{
		id: '2240',
		value: 'Береза',
	},
	{
		id: '11137',
		value: 'Вишевичи',
	},
	{
		id: '2590',
		value: 'Высокое',
	},
	{
		id: '11135',
		value: 'Галево',
	},
	{
		id: '2241',
		value: 'Ганцевичи',
	},
	{
		id: '2741',
		value: 'Давид-Городок',
	},
	{
		id: '2242',
		value: 'Дрогичин',
	},
	{
		id: '11138',
		value: 'Еремичи',
	},
	{
		id: '2243',
		value: 'Жабинка',
	},
	{
		id: '2743',
		value: 'Иваново (Брестская область)',
	},
	{
		id: '2245',
		value: 'Ивацевичи',
	},
	{
		id: '2246',
		value: 'Каменец',
	},
	{
		id: '2247',
		value: 'Кобрин',
	},
	{
		id: '2745',
		value: 'Коссово',
	},
	{
		id: '11136',
		value: 'Ленинский (Брестская область)',
	},
	{
		id: '2248',
		value: 'Лунинец',
	},
	{
		id: '2249',
		value: 'Ляховичи',
	},
	{
		id: '11134',
		value: 'Малеч',
	},
	{
		id: '2250',
		value: 'Малорита',
	},
	{
		id: '2650',
		value: 'Микашевичи',
	},
	{
		id: '11131',
		value: 'Мотоль',
	},
	{
		id: '11133',
		value: 'Пинковичи',
	},
	{
		id: '2251',
		value: 'Пинск',
	},
	{
		id: '2252',
		value: 'Пружаны',
	},
	{
		id: '11132',
		value: 'Рубель',
	},
	{
		id: '11129',
		value: 'Ружаны',
	},
	{
		id: '2253',
		value: 'Столин',
	},
	{
		id: '6007',
		value: 'Телеханы',
	},
	{
		id: '11139',
		value: 'Жемчужный',
	},
	{
		id: '1005',
		value: 'Витебск',
	},
	{
		id: '11150',
		value: 'Азино',
	},
	{
		id: '11149',
		value: 'Андреевщина',
	},
	{
		id: '11140',
		value: 'Бабиничи',
	},
	{
		id: '2632',
		value: 'Барань',
	},
	{
		id: '11145',
		value: 'Бегомль',
	},
	{
		id: '2254',
		value: 'Бешенковичи',
	},
	{
		id: '11142',
		value: 'Болбасово',
	},
	{
		id: '2255',
		value: 'Браслав',
	},
	{
		id: '2256',
		value: 'Верхнедвинск',
	},
	{
		id: '11152',
		value: 'Видзы',
	},
	{
		id: '11143',
		value: 'Воропаево',
	},
	{
		id: '2257',
		value: 'Глубокое',
	},
	{
		id: '2258',
		value: 'Городок (Витебская область)',
	},
	{
		id: '2742',
		value: 'Дисна',
	},
	{
		id: '2259',
		value: 'Докшицы',
	},
	{
		id: '2260',
		value: 'Дубровно',
	},
	{
		id: '11151',
		value: 'Коханово',
	},
	{
		id: '11146',
		value: 'Крулевщина',
	},
	{
		id: '2261',
		value: 'Лепель',
	},
	{
		id: '2262',
		value: 'Лиозно',
	},
	{
		id: '2263',
		value: 'Миоры',
	},
	{
		id: '11147',
		value: 'Новка',
	},
	{
		id: '2484',
		value: 'Новолукомль',
	},
	{
		id: '2366',
		value: 'Новополоцк',
	},
	{
		id: '11148',
		value: 'Оболь',
	},
	{
		id: '2264',
		value: 'Орша',
	},
	{
		id: '11144',
		value: 'Подсвилье',
	},
	{
		id: '2265',
		value: 'Полоцк',
	},
	{
		id: '2266',
		value: 'Поставы',
	},
	{
		id: '2267',
		value: 'Россоны',
	},
	{
		id: '2268',
		value: 'Сенно',
	},
	{
		id: '2269',
		value: 'Толочин',
	},
	{
		id: '2270',
		value: 'Ушачи',
	},
	{
		id: '11141',
		value: 'Фариново',
	},
	{
		id: '2271',
		value: 'Чашники',
	},
	{
		id: '2272',
		value: 'Шарковщина',
	},
	{
		id: '2273',
		value: 'Шумилино',
	},
	{
		id: '11153',
		value: 'Заслоново',
	},
	{
		id: '1003',
		value: 'Гомель',
	},
	{
		id: '2274',
		value: 'Брагин',
	},
	{
		id: '2275',
		value: 'Буда-Кошелево',
	},
	{
		id: '11126',
		value: 'Василевичи',
	},
	{
		id: '2276',
		value: 'Ветка',
	},
	{
		id: '11127',
		value: 'Глушковичи',
	},
	{
		id: '2277',
		value: 'Добруш',
	},
	{
		id: '2278',
		value: 'Ельск',
	},
	{
		id: '2279',
		value: 'Житковичи',
	},
	{
		id: '2280',
		value: 'Жлобин',
	},
	{
		id: '2281',
		value: 'Калинковичи',
	},
	{
		id: '11124',
		value: 'Козенки',
	},
	{
		id: '11125',
		value: 'Комарин',
	},
	{
		id: '6320',
		value: 'Копаткевичи',
	},
	{
		id: '2282',
		value: 'Корма',
	},
	{
		id: '2283',
		value: 'Лельчицы',
	},
	{
		id: '2284',
		value: 'Лоев',
	},
	{
		id: '11121',
		value: 'Медков',
	},
	{
		id: '2285',
		value: 'Мозырь',
	},
	{
		id: '2286',
		value: 'Наровля',
	},
	{
		id: '2747',
		value: 'Октябрьский (Гомельская область)',
	},
	{
		id: '11123',
		value: 'Паричи',
	},
	{
		id: '2288',
		value: 'Петриков',
	},
	{
		id: '2289',
		value: 'Речица',
	},
	{
		id: '2290',
		value: 'Рогачев',
	},
	{
		id: '2291',
		value: 'Светлогорск (Беларусь)',
	},
	{
		id: '11119',
		value: 'Сосновый Бор (Гомельская область)',
	},
	{
		id: '2523',
		value: 'Туров',
	},
	{
		id: '11122',
		value: 'Уваровичи',
	},
	{
		id: '2292',
		value: 'Хойники',
	},
	{
		id: '11120',
		value: 'Чёнки',
	},
	{
		id: '2293',
		value: 'Чечерск',
	},
	{
		id: '11128',
		value: 'Озерщина',
	},
	{
		id: '11161',
		value: 'Бенякони',
	},
	{
		id: '2588',
		value: 'Березовка (Гродненская область)',
	},
	{
		id: '2294',
		value: 'Берестовица',
	},
	{
		id: '2740',
		value: 'Большая Берестовица',
	},
	{
		id: '11159',
		value: 'Вертилишки',
	},
	{
		id: '2295',
		value: 'Волковыск',
	},
	{
		id: '2296',
		value: 'Вороново',
	},
	{
		id: '2297',
		value: 'Дятлово',
	},
	{
		id: '2298',
		value: 'Зельва',
	},
	{
		id: '2299',
		value: 'Ивье',
	},
	{
		id: '11154',
		value: 'Козловщина',
	},
	{
		id: '2300',
		value: 'Кореличи',
	},
	{
		id: '11160',
		value: 'Коробчицы',
	},
	{
		id: '11158',
		value: 'Красносельский (Гродненская область)',
	},
	{
		id: '2301',
		value: 'Лида',
	},
	{
		id: '2631',
		value: 'Мир',
	},
	{
		id: '2302',
		value: 'Мосты',
	},
	{
		id: '2303',
		value: 'Новогрудок',
	},
	{
		id: '11155',
		value: 'Новоельня',
	},
	{
		id: '2304',
		value: 'Островец',
	},
	{
		id: '2305',
		value: 'Ошмяны',
	},
	{
		id: '11156',
		value: 'Россь',
	},
	{
		id: '2306',
		value: 'Свислочь',
	},
	{
		id: '2589',
		value: 'Скидель',
	},
	{
		id: '2307',
		value: 'Слоним',
	},
	{
		id: '2308',
		value: 'Сморгонь',
	},
	{
		id: '11157',
		value: 'Сопоцкин',
	},
	{
		id: '2309',
		value: 'Щучин',
	},
	{
		id: '11178',
		value: 'Радунь',
	},
	{
		id: '1006',
		value: 'Гродно',
	},
	{
		id: '1002',
		value: 'Минск',
	},
	{
		id: '11108',
		value: 'Аксаковщина',
	},
	{
		id: '2310',
		value: 'Березино',
	},
	{
		id: '11093',
		value: 'Большое Стиклево',
	},
	{
		id: '2311',
		value: 'Борисов',
	},
	{
		id: '6936',
		value: 'Боровляны',
	},
	{
		id: '2312',
		value: 'Вилейка',
	},
	{
		id: '2313',
		value: 'Воложин',
	},
	{
		id: '11098',
		value: 'Гацук',
	},
	{
		id: '2314',
		value: 'Дзержинск (Беларусь)',
	},
	{
		id: '11113',
		value: 'Дружный (Минская область)',
	},
	{
		id: '11099',
		value: 'Дукора',
	},
	{
		id: '2315',
		value: 'Жодино',
	},
	{
		id: '2316',
		value: 'Заславль',
	},
	{
		id: '11089',
		value: 'Ивенец',
	},
	{
		id: '2317',
		value: 'Клецк',
	},
	{
		id: '11102',
		value: 'Колодищи',
	},
	{
		id: '2318',
		value: 'Копыль',
	},
	{
		id: '11107',
		value: 'Корзуны',
	},
	{
		id: '11090',
		value: 'Красная слобода',
	},
	{
		id: '2319',
		value: 'Крупки',
	},
	{
		id: '6006',
		value: 'Лесной (Минская область)',
	},
	{
		id: '2320',
		value: 'Логойск',
	},
	{
		id: '11104',
		value: 'Лошница',
	},
	{
		id: '2746',
		value: 'Любань',
	},
	{
		id: '2322',
		value: 'Марьина Горка',
	},
	{
		id: '6375',
		value: 'Мачулищи',
	},
	{
		id: '6772',
		value: 'Михановичи',
	},
	{
		id: '2323',
		value: 'Молодечно',
	},
	{
		id: '2324',
		value: 'Мядель',
	},
	{
		id: '11086',
		value: 'Нарочь',
	},
	{
		id: '2325',
		value: 'Несвиж',
	},
	{
		id: '11106',
		value: 'Новосады',
	},
	{
		id: '11096',
		value: 'Новоселье (Минский район)',
	},
	{
		id: '11109',
		value: 'Новый Свержень',
	},
	{
		id: '11105',
		value: 'Носилово',
	},
	{
		id: '11097',
		value: 'Островы',
	},
	{
		id: '11095',
		value: 'Острошицкий Городок',
	},
	{
		id: '11110',
		value: 'Плещеницы',
	},
	{
		id: '11111',
		value: 'Пуховичи',
	},
	{
		id: '6321',
		value: 'Радошковичи',
	},
	{
		id: '11091',
		value: 'Раков',
	},
	{
		id: '11094',
		value: 'Ратомка',
	},
	{
		id: '11112',
		value: 'Руденск',
	},
	{
		id: '11103',
		value: 'Свислочь (Минская область)',
	},
	{
		id: '11115',
		value: 'Слобода ( Минская область)',
	},
	{
		id: '2326',
		value: 'Слуцк',
	},
	{
		id: '11088',
		value: 'Смиловичи',
	},
	{
		id: '2327',
		value: 'Смолевичи',
	},
	{
		id: '2328',
		value: 'Солигорск',
	},
	{
		id: '2749',
		value: 'Старые Дороги',
	},
	{
		id: '2330',
		value: 'Столбцы',
	},
	{
		id: '11100',
		value: 'Углы',
	},
	{
		id: '2331',
		value: 'Узда',
	},
	{
		id: '11114',
		value: 'Усяж',
	},
	{
		id: '2394',
		value: 'Фаниполь',
	},
	{
		id: '11079',
		value: 'Хатежино',
	},
	{
		id: '11092',
		value: 'Хотляны',
	},
	{
		id: '2332',
		value: 'Червень',
	},
	{
		id: '11087',
		value: 'Чисть',
	},
	{
		id: '11101',
		value: 'Энергетиков',
	},
	{
		id: '11116',
		value: 'Дричин',
	},
	{
		id: '1004',
		value: 'Могилев',
	},
	{
		id: '2333',
		value: 'Белыничи',
	},
	{
		id: '2334',
		value: 'Бобруйск',
	},
	{
		id: '2335',
		value: 'Быхов',
	},
	{
		id: '2336',
		value: 'Глуск',
	},
	{
		id: '2337',
		value: 'Горки (Республика Беларусь)',
	},
	{
		id: '2338',
		value: 'Дрибин',
	},
	{
		id: '6005',
		value: 'Кадино (Могилевская область)',
	},
	{
		id: '2744',
		value: 'Кировск (Могилевская область)',
	},
	{
		id: '2340',
		value: 'Климовичи',
	},
	{
		id: '2341',
		value: 'Кличев',
	},
	{
		id: '2342',
		value: 'Костюковичи',
	},
	{
		id: '2343',
		value: 'Краснополье (Могилевская область)',
	},
	{
		id: '2344',
		value: 'Кричев',
	},
	{
		id: '2345',
		value: 'Круглое',
	},
	{
		id: '11117',
		value: 'Лапичи',
	},
	{
		id: '11118',
		value: 'Ленино  (Могилевская область)',
	},
	{
		id: '2346',
		value: 'Мстиславль',
	},
	{
		id: '2347',
		value: 'Осиповичи',
	},
	{
		id: '2748',
		value: 'Славгород',
	},
	{
		id: '2349',
		value: 'Хотимск',
	},
	{
		id: '2350',
		value: 'Чаусы',
	},
	{
		id: '2351',
		value: 'Чериков',
	},
	{
		id: '2352',
		value: 'Шклов',
	},
];

const gruzCities = [
	{
		id: '2820',
		value: 'Ахалцихе',
	},
	{
		id: '2814',
		value: 'Батуми',
	},
	{
		id: '2821',
		value: 'Болниси',
	},
	{
		id: '2822',
		value: 'Боржоми',
	},
	{
		id: '2823',
		value: 'Гардабани',
	},
	{
		id: '2824',
		value: 'Гори',
	},
	{
		id: '2825',
		value: 'Зестафони',
	},
	{
		id: '2826',
		value: 'Зугдиди',
	},
	{
		id: '2827',
		value: 'Каспи',
	},
	{
		id: '2828',
		value: 'Кобулети',
	},
	{
		id: '2813',
		value: 'Кутаиси',
	},
	{
		id: '2829',
		value: 'Марнеули',
	},
	{
		id: '2830',
		value: 'Мцхета',
	},
	{
		id: '2831',
		value: 'Озургети',
	},
	{
		id: '2832',
		value: 'Поти',
	},
	{
		id: '2815',
		value: 'Рустави',
	},
	{
		id: '2833',
		value: 'Сагареджо',
	},
	{
		id: '2834',
		value: 'Самтредиа',
	},
	{
		id: '2835',
		value: 'Сенаки',
	},
	{
		id: '2758',
		value: 'Тбилиси',
	},
	{
		id: '2836',
		value: 'Телави',
	},
	{
		id: '2837',
		value: 'Ткибули',
	},
	{
		id: '2838',
		value: 'Хашури',
	},
	{
		id: '2839',
		value: 'Хони',
	},
	{
		id: '2840',
		value: 'Цхалтубо',
	},
	{
		id: '2841',
		value: 'Чиатура',
	},
];

const kirgizCities = [
	{
		id: '2842',
		value: 'Балыкчы',
	},
	{
		id: '2797',
		value: 'Баткен',
	},
	{
		id: '2760',
		value: 'Бишкек',
	},
	{
		id: '2795',
		value: 'Джалал-Абад',
	},
	{
		id: '2843',
		value: 'Исфана',
	},
	{
		id: '3668',
		value: 'Кадамжай',
	},
	{
		id: '2844',
		value: 'Кант',
	},
	{
		id: '2845',
		value: 'Кара-Балта',
	},
	{
		id: '2792',
		value: 'Каракол',
	},
	{
		id: '2846',
		value: 'Кара-Куль',
	},
	{
		id: '2847',
		value: 'Кара-Суу',
	},
	{
		id: '2848',
		value: 'Кербен',
	},
	{
		id: '2849',
		value: 'Кочкор-Ата',
	},
	{
		id: '2850',
		value: 'Кызыл-Кия',
	},
	{
		id: '2851',
		value: 'Майлуу-Суу',
	},
	{
		id: '2794',
		value: 'Нарын',
	},
	{
		id: '2852',
		value: 'Ноокат',
	},
	{
		id: '2796',
		value: 'Ош',
	},
	{
		id: '2853',
		value: 'Сулюкта',
	},
	{
		id: '2793',
		value: 'Талас',
	},
	{
		id: '2854',
		value: 'Таш-Кумыр',
	},
	{
		id: '2855',
		value: 'Токмок',
	},
	{
		id: '2856',
		value: 'Токтогул',
	},
	{
		id: '2857',
		value: 'Узген',
	},
	{
		id: '2858',
		value: 'Чолпон-Ата',
	},
];

const uzbekCities = [
	{
		id: '2763',
		value: 'Аккурган',
	},
	{
		id: '2764',
		value: 'Акташ (Узбекистан)',
	},
	{
		id: '2765',
		value: 'Алат (Узбекистан)',
	},
	{
		id: '2766',
		value: 'Алмалык',
	},
	{
		id: '2767',
		value: 'Ангрен',
	},
	{
		id: '2768',
		value: 'Андижан',
	},
	{
		id: '2769',
		value: 'Асака',
	},
	{
		id: '2770',
		value: 'Ахангаран',
	},
	{
		id: '2771',
		value: 'Ахунбабаев',
	},
	{
		id: '2772',
		value: 'Байсун',
	},
	{
		id: '2773',
		value: 'Бахт',
	},
	{
		id: '2774',
		value: 'Бекабад',
	},
	{
		id: '2775',
		value: 'Беруни',
	},
	{
		id: '2776',
		value: 'Бешарык',
	},
	{
		id: '2777',
		value: 'Бешкент',
	},
	{
		id: '2859',
		value: 'Бука',
	},
	{
		id: '2860',
		value: 'Булунгур',
	},
	{
		id: '2861',
		value: 'Бустан',
	},
	{
		id: '2781',
		value: 'Бухара',
	},
	{
		id: '2862',
		value: 'Вабкент',
	},
	{
		id: '2863',
		value: 'Гагарин (Узбекистан)',
	},
	{
		id: '2864',
		value: 'Газалкент',
	},
	{
		id: '2865',
		value: 'Газли',
	},
	{
		id: '2866',
		value: 'Галаасия',
	},
	{
		id: '2867',
		value: 'Галляарал',
	},
	{
		id: '2868',
		value: 'Гиждуван',
	},
	{
		id: '2869',
		value: 'Гузар',
	},
	{
		id: '2870',
		value: 'Гулистан',
	},
	{
		id: '2871',
		value: 'Даштабад',
	},
	{
		id: '2872',
		value: 'Денау',
	},
	{
		id: '2873',
		value: 'Джамбай',
	},
	{
		id: '2874',
		value: 'Джаркурган',
	},
	{
		id: '2786',
		value: 'Джизак',
	},
	{
		id: '2875',
		value: 'Джума',
	},
	{
		id: '2876',
		value: 'Дустабад',
	},
	{
		id: '2877',
		value: 'Дустлик',
	},
	{
		id: '2878',
		value: 'Зарафшан',
	},
	{
		id: '2879',
		value: 'Иштыхан',
	},
	{
		id: '2880',
		value: 'Каган',
	},
	{
		id: '2881',
		value: 'Камаши',
	},
	{
		id: '2882',
		value: 'Каракуль',
	},
	{
		id: '2883',
		value: 'Карасу',
	},
	{
		id: '2783',
		value: 'Карши',
	},
	{
		id: '2884',
		value: 'Касан',
	},
	{
		id: '2885',
		value: 'Касансай',
	},
	{
		id: '2886',
		value: 'Каттакурган',
	},
	{
		id: '2887',
		value: 'Келес',
	},
	{
		id: '2888',
		value: 'Китаб',
	},
	{
		id: '2784',
		value: 'Коканд',
	},
	{
		id: '2889',
		value: 'Кува',
	},
	{
		id: '2890',
		value: 'Кувасай',
	},
	{
		id: '2891',
		value: 'Кумкурган',
	},
	{
		id: '2892',
		value: 'Кунград',
	},
	{
		id: '2893',
		value: 'Кургантепа',
	},
	{
		id: '2894',
		value: 'Кызылтепа',
	},
	{
		id: '2895',
		value: 'Мангит',
	},
	{
		id: '2785',
		value: 'Маргилан',
	},
	{
		id: '2896',
		value: 'Мархамат',
	},
	{
		id: '2897',
		value: 'Мубарек',
	},
	{
		id: '2898',
		value: 'Муйнак',
	},
	{
		id: '6381',
		value: 'Вуадиль',
	},
	{
		id: '2790',
		value: 'Навои',
	},
	{
		id: '2779',
		value: 'Наманган',
	},
	{
		id: '2780',
		value: 'Нукус',
	},
	{
		id: '2899',
		value: 'Нурабад',
	},
	{
		id: '2900',
		value: 'Нурата',
	},
	{
		id: '2901',
		value: 'Пайарык',
	},
	{
		id: '2902',
		value: 'Пайтуг',
	},
	{
		id: '2903',
		value: 'Пап',
	},
	{
		id: '2904',
		value: 'Паркент',
	},
	{
		id: '2905',
		value: 'Пахтаабад',
	},
	{
		id: '2906',
		value: 'Пахтакор',
	},
	{
		id: '2907',
		value: 'Питнак',
	},
	{
		id: '2908',
		value: 'Пскент',
	},
	{
		id: '2909',
		value: 'Риштан',
	},
	{
		id: '2910',
		value: 'Ромитан',
	},
	{
		id: '2778',
		value: 'Самарканд',
	},
	{
		id: '2911',
		value: 'Сырдарья',
	},
	{
		id: '2912',
		value: 'Тахиаташ',
	},
	{
		id: '2759',
		value: 'Ташкент',
	},
	{
		id: '2789',
		value: 'Термез',
	},
	{
		id: '2913',
		value: 'Тинчлик',
	},
	{
		id: '2914',
		value: 'Тойтепа',
	},
	{
		id: '2915',
		value: 'Туракурган',
	},
	{
		id: '2916',
		value: 'Турткуль',
	},
	{
		id: '2788',
		value: 'Ургенч',
	},
	{
		id: '2917',
		value: 'Ургут',
	},
	{
		id: '2918',
		value: 'Учкудук',
	},
	{
		id: '2919',
		value: 'Учкурган',
	},
	{
		id: '2782',
		value: 'Фергана',
	},
	{
		id: '2920',
		value: 'Хаккулабад',
	},
	{
		id: '2921',
		value: 'Ханабад',
	},
	{
		id: '2922',
		value: 'Хива',
	},
	{
		id: '2923',
		value: 'Ходжаабад',
	},
	{
		id: '2924',
		value: 'Ходжейли',
	},
	{
		id: '2925',
		value: 'Чартак',
	},
	{
		id: '2926',
		value: 'Челек',
	},
	{
		id: '2927',
		value: 'Чимбай',
	},
	{
		id: '2928',
		value: 'Чиназ',
	},
	{
		id: '2929',
		value: 'Чиракчи',
	},
	{
		id: '2787',
		value: 'Чирчик',
	},
	{
		id: '2930',
		value: 'Чуст',
	},
	{
		id: '2931',
		value: 'Шаргунь',
	},
	{
		id: '2932',
		value: 'Шафиркан',
	},
	{
		id: '2791',
		value: 'Шахрисабз',
	},
	{
		id: '2933',
		value: 'Шахрихан',
	},
	{
		id: '2934',
		value: 'Шерабад',
	},
	{
		id: '2935',
		value: 'Ширин',
	},
	{
		id: '2936',
		value: 'Шуманай',
	},
	{
		id: '2937',
		value: 'Шурчи',
	},
	{
		id: '2938',
		value: 'Яйпан',
	},
	{
		id: '2939',
		value: 'Яккабаг',
	},
	{
		id: '2940',
		value: 'Янгиер',
	},
	{
		id: '2941',
		value: 'Янги-Нишан',
	},
	{
		id: '2942',
		value: 'Янгирабад',
	},
	{
		id: '2943',
		value: 'Янгиюль',
	},
];

const armenCities = [
	{ value: 'Ереван' },
	{ value: 'Гюмри' },
	{ value: 'Ванадзор' },
	{ value: 'Армавир' },
	{ value: 'Степанакерт' },
	{ value: 'Лори' },
	{ value: 'Гавар' },
	{ value: 'Арташат' },
	{ value: 'Масис' },
	{ value: 'Банак' },
];

const mainSNGcities = [
	{ value: 'Астана' },
	{ value: 'Алматы' },
	{ value: 'Шымкент' },
	{ value: 'Караганда' },
	{ value: 'Актобе' },
	{ value: 'Тараз' },
	{ value: 'Бишкек' },
	{ value: 'Душанбе' },
	{ value: 'Ташкент' },
	{ value: 'Ашхабад' },
	{ value: 'Баку' },
	{ value: 'Гянджа' },
	{ value: 'Ереван' },
	{ value: 'Минск' },
	{ value: 'Гомель' },
	{ value: 'Могилёв' },
	{ value: 'Витебск' },
	{ value: 'Брест (Беларусь)' },
];

export const cities = [
	...russianCities,
	// ...kazahstanCities,
	// ...azerbCities,
	// ...belarusCities,
	// ...gruzCities,
	// ...kirgizCities,
	// ...uzbekCities,
	// ...armenCities,
	...mainSNGcities,
].map((city, id) => ({ ...city, id }));
