import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
// import styled from 'styled-components';
import PreviewReklama from './PreviewReklama';
import ReklamaService from '../../services/ReklamaService';
import config from '../../settings/config';
import styles from './reklama.module.css';
import { MyCKEditor } from '../../components/MyCKEditor/MyCKEditor';

const AdEdit = () => {
	const navigate = useNavigate();

	const [ready, setReady] = useState(false);
	const [ad, setAd] = useState([]);

	const { id } = useParams();

	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [url, setUrl] = useState('');
	const [preview_url, setPreview_url] = useState([]);
	const [photo_url, setPhoto_url] = useState({});
	const [file_url, setFile_url] = useState('');

	const [top_place, setTopPlace] = useState('');
	const [side_place, setSidePlace] = useState('');
	const [card_place, setCardPlace] = useState('');
	const [card_text, setCard_text] = useState('');
	const [overlay, setOverlay] = useState('');

	const [deletedFiles, setDeletedFiles] = useState('');

	const top_placeRef = useRef(null);
	const side_placeRef = useRef(null);
	const card_placeRef = useRef(null);
	const card_textRef = useRef(null);
	const overlayRef = useRef(null);

	useEffect(() => {
		(async () => {
			try {
				const response = await ReklamaService.getReklamaById(id);

				setTitle(response?.data?.title);
				setDescription(response?.data?.description);
				setUrl(response?.data?.url);
				setPreview_url(response?.data?.preview_url);
				setPhoto_url(response?.data?.photo_url);
				setFile_url(response?.data?.file_url);

				setTopPlace(response?.data?.top_place);
				setSidePlace(response?.data?.side_place);
				setCardPlace(response?.data?.card_place);
				setCard_text(response?.data?.card_text);
				setOverlay(response?.data?.overlay);

				// console.log(response)
			} catch (e) {
				console.error(e);
			} finally {
				setReady(true);
			}
		})();
	}, []);

	const handleForm = (e) => {
		e.preventDefault();

		const dataForm = e.target;
		console.log(dataForm);

		// const title = dataForm[0]?.value;
		// const description = dataForm[1]?.value;
		// const url = dataForm[2]?.value;
		// const preview_url = dataForm[3]?.files;
		// const photo_url = dataForm[5]?.files;
		const file_url_new = dataForm.querySelector('#file-input').files;

		const top_place = top_placeRef?.current?.value;
		const side_place = side_placeRef?.current?.value;
		const card_place = card_placeRef?.current?.value;
		const card_text = card_textRef?.current?.value;
		const overlay = overlayRef?.current?.value;

		console.log('title >> ', title); //
		console.log('description >> ', description); //
		console.log('preview_url >> ', preview_url); //
		console.log('photo_url >> ', photo_url); //
		console.log('file_url >> ', file_url_new);
		console.log('site >>', url);
		console.log('top_place >> ', top_place); //
		console.log('side_place >> ', side_place); //
		console.log('card_place >> ', card_place); //
		console.log('card_text >> ', card_text);
		console.log('overlay >> ', overlay); //

		console.log('deletedFiles >> ', deletedFiles);

		if (
			title < 1 ||
			description < 1 ||
			!top_place ||
			!side_place ||
			!card_place ||
			overlay < 1
		) {
			console.log('rejected');
			return;
		}

		const formData = new FormData();

		formData.append('title', title);
		formData.append('description', description);
		formData.append('url', url);

		formData.append('top_place', top_place);
		formData.append('side_place', side_place);
		formData.append('card_place', card_place);
		formData.append('card_text', card_text);
		formData.append('overlay', overlay);

		Object.values(deletedFiles).forEach((file) => {
			formData.append('delete_files', file);
		});

		Object.values(preview_url).forEach((photo) => {
			formData.append('preview_url', photo);
		});

		Object.values(photo_url).forEach((photo) => {
			formData.append('photo_url', photo);
		});

		Object.values(file_url_new).forEach((file) => {
			formData.append('file_url', file);
		});

		ReklamaService.updateReklama(id, formData);
		navigate(`/reklama`);
	};

	const removePhotoPreview = (e, id) => {
		e.preventDefault();
		console.log(e);
		const fileName = e.target?.parentElement.children[0].currentSrc
			.split('/')
			.splice(-1, 1)[0];

		setDeletedFiles((state) => [...state, fileName]);
		setPreview_url({});
	};

	const removePhotoMain = (e, id) => {
		e.preventDefault();
		// setDeletedMain(id);

		const fileName = e.target?.parentElement.children[0].currentSrc
			.split('/')
			.splice(-1, 1)[0];

		setDeletedFiles((state) => [...state, fileName]);
		setPhoto_url({});
	};

	const removeFile = (e, id) => {
		e.preventDefault();
		// setDeletedFile(id);
		const fileName = e.target?.parentElement.children[0].href
			.split('/')
			.splice(-1, 1)[0];
		console.log(fileName);

		setDeletedFiles((state) => [...state, fileName]);
		setFile_url('');
	};

	const handleAddPreview = (e) => {
		let arrayofFiles = [];

		for (var i = 0; i < e.target?.files?.length; i++) {
			arrayofFiles.push(e.target?.files[i]);
		}

		let images = [];

		arrayofFiles.map((e) => {
			const ImageUrl = URL.createObjectURL(e);

			images.push(ImageUrl);
		});
		console.log(images);
		setPhoto_url(images);
	};

	const handleAddMain = (e) => {
		let arrayofFiles = [];

		for (var i = 0; i < e.target?.files?.length; i++) {
			arrayofFiles.push(e.target?.files[i]);
		}

		let images = [];

		arrayofFiles.map((e) => {
			const ImageUrl = URL.createObjectURL(e);

			images.push(ImageUrl);
		});

		setPhoto_url(images);
	};

	const removeFileFromDownloads = (e, indx) => {
		e.preventDefault();
		console.log(e);
		const fileName = e.target?.parentElement.children[0].innerText;
		// console.log(fileName)
		const index = e.target?.id;

		let newPreview = [...file_url];
		let newDeletedFile = [...deletedFiles];

		newPreview.splice(indx, 1);
		newDeletedFile.push(fileName);

		setFile_url(newPreview);
		setDeletedFiles(newDeletedFile);
	};

	return (
		<div className={styles.reklamaWrapper}>
			{ready && (
				<form className={styles.form} onSubmit={handleForm}>
					<p>
						Заголовок <b>Пустым быть не может!</b>
					</p>
					<input
						placeholder="Введите заголовок, основной"
						value={title}
						onChange={(e) => setTitle(e.target?.value)}
					/>
					<MyCKEditor
						onChange={(value) => setDescription(value)}
						className={styles.CKEditor}
						startData={description}
					/>
					<p>Основной сайт компании (будет помещен в кнопку)</p>
					<input
						placeholder="Введите сайт"
						value={url}
						onChange={(e) => setUrl(e.target?.value)}
					/>

					<p>
						Выберете превью фото, небольшого разрешения.{' '}
						<b>Пустым быть не может!</b>
					</p>
					<input type="file" onChange={handleAddPreview} />

					<div className={styles.preview}>
						{preview_url?.length !== 0 && (
							<img
								src={`${config?.UPLOAD_API_URL}/uploads/ad/${preview_url[0]?.filename}`}
								alt=""
								width={500}
							/>
						)}
						<button id={id} key={id} onClick={(e) => removePhotoPreview(e, id)}>
							Очистить
						</button>
					</div>

					<p>
						Выберете основное фото, разрешение побольше.{' '}
						<b>Пустым быть не может!</b>
					</p>
					<input type="file" onChange={handleAddMain} />

					<div className={styles.preview}>
						{photo_url.length !== 0 && (
							<img
								src={`${config?.UPLOAD_API_URL}/uploads/ad/${photo_url[0]?.filename}`}
								alt=""
								width={500}
							/>
						)}
						<button id={id} key={id} onClick={(e) => removePhotoMain(e, id)}>
							Очистить
						</button>
					</div>

					<p>Выберете до 5 файлов в дополнение. Еще {5 - file_url?.length}</p>
					<input type="file" multiple id="file-input" />

					<div className={styles.preview}>
						{file_url &&
							file_url?.map((item, index) => (
								<div key={index}>
									<a
										href={`${config?.UPLOAD_API_URL}uploads/ad/${item?.filename}`}
									>
										{item?.filename}
									</a>
									<button
										className={styles.removeFile}
										id={index}
										key={index}
										onClick={(e) => removeFileFromDownloads(e, index)}
									>
										Удалить
									</button>
								</div>
							))}
						<button
							className={styles.cleanFiles}
							id={id}
							key={id}
							onClick={(e) => removeFile(e, id)}
						>
							Очистить
						</button>
					</div>

					<div className={styles.borderedDiv}>
						<p>
							Размещать или нет в <b>верхнем</b> блоке?
						</p>
						<p>Если 0 - не размещать совсем в этом блоке</p>
						<p>Выберете место в очереди от 1 до 12</p>
						<p>
							<b>Пустым быть не может!</b>
						</p>
						<input
							type="number"
							min="0"
							max="12"
							value={top_place}
							onChange={(e) => setTopPlace(e.target?.value)}
							ref={top_placeRef}
						/>
					</div>

					<div className={styles.borderedDiv}>
						<p>
							Размещать или нет в блоке <b>слева</b>?
						</p>
						<p>Если 0 - не размещать совсем в этом блоке</p>
						<p>Выберете место в очереди от 1 до 12</p>
						<p>
							<b>Пустым быть не может!</b>
						</p>
						<input
							type="number"
							min="0"
							max="12"
							value={side_place}
							onChange={(e) => setSidePlace(e.target?.value)}
							ref={side_placeRef}
						/>
					</div>

					<div className={styles.borderedDiv}>
						<p>
							Размещать или нет в <b>карточках</b>?
						</p>
						<p>Если 0 - не размещать совсем в этом блоке</p>
						<p>Выберете место в очереди от 1 до 12</p>
						<p>
							<b>Пустым быть не может!</b>
						</p>
						<input
							type="number"
							min="0"
							max="12"
							value={card_place}
							onChange={(e) => setCardPlace(e.target?.value)}
							ref={card_placeRef}
						/>
						<p>Текст который будет написан на карточке под Заголовком</p>
						<input
							placeholder="Введите текст карточки"
							value={card_text}
							onChange={(e) => setCard_text(e.target?.value)}
							ref={card_textRef}
						/>
					</div>

					<p>
						Оверлей. <b>Пустым быть не может!</b>
					</p>
					<input
						placeholder="Введите текст карточки"
						value={overlay}
						onChange={(e) => setOverlay(e.target?.value)}
						ref={overlayRef}
					/>

					<button type="submite">Сохранить</button>
				</form>
			)}
			{/* <div className={styles.previewReklama}>
				<PreviewReklama description={description} />
			</div> */}
		</div>
	);
};

export default AdEdit;
