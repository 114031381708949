import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useRef, useState } from 'react';

const editorConfiguration = {
	toolbar: [
		'undo',
		'redo',
		'|',
		'heading',
		'|',
		'bold',
		'italic',
		'|',
		'link',
		// 'uploadImage',
		'|',
		'numberedList',
		'bulletedList',
	],
	// plugins: [SimpleUploadAdapter],
	simpleUpload: {
		// Feature configuration.
		uploadUrl: 'http://localhost:5500/api',

		// Enable the XMLHttpRequest.withCredentials property.
		withCredentials: true,
	},
	placeholder: 'Введите текст, используйте инструменты форматирования',
};

export const MyCKEditor = ({ onChange, className, startData = '' }) => {
	return (
		<div className={className}>
			<CKEditor
				editor={Editor}
				config={editorConfiguration}
				data={startData}
				onReady={(editor) => {
					// You can store the "editor" and use when it is needed.
					// console.log('Editor is ready to use!', editor);
				}}
				onChange={(_event, editor) => {
					onChange(editor.getData());
				}}
				// onBlur={(event, editor) => {
				// 	console.log('Blur.', editor);
				// }}
				// onFocus={(event, editor) => {
				// 	console.log('Focus.', editor);
				// }}
			/>
		</div>
	);
};
