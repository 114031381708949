import React, { useEffect, useState } from 'react';
import s from './usersStat.module.css';
// import { observer } from 'mobx-react-lite';
import { PageTitle } from '../../../components/PageTitle/PageTitle';
import { FilterList } from '../../../components/FilterList/FilterList';
import { UsersStatByUser } from './UsersStatByUser';
import { UsersStatByDays } from './UsersStatByDays';
import { useNavigate } from 'react-router-dom';

const UsersStat = () => {
	const navigate = useNavigate();
	//Состояние фильтров
	const userFilter = [{ label: 'По дням' }, { label: 'Пользователи' }];
	const [currUserFilterIndex, setCurrUserFilterIndex] = useState(0);
	//Показать стат. по пользователям если выбран фильтр "Пользователи"
	const isShowStatByUser = currUserFilterIndex === 1;

	useEffect(() => {
		navigate('/statistics/users/1'); //сброс на 1 страницу
	}, [isShowStatByUser]);

	return (
		<>
			<div className={s.wrapper}>
				<PageTitle>Посещаемость</PageTitle>
				<span className={s.label}>
					Статистика визитов пользователей на портал. Ведется с 01.02.2024
				</span>
				<FilterList
					dataItems={userFilter}
					currentItem={currUserFilterIndex}
					choiceItem={setCurrUserFilterIndex}
				/>

				{isShowStatByUser ? <UsersStatByUser /> : <UsersStatByDays />}
			</div>
		</>
	);
};

// export default observer(UsersStat);
export default UsersStat;
