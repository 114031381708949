import React, { useState } from 'react';
import s from './zakazes.module.css';
//dataList: [['key', value]...]
export const StatisticsList = ({ dataList, totalFiles }) => {
	if (!dataList[0]) {
		return (
			<p style={{ textAlign: 'center', marginTop: '40px' }}>
				По выбранным фильтрам данные не найдены
			</p>
		);
	}
	// Найдем самое большое значение для расчета кол-ва ед. в 1 % графика
	const listValues = dataList.map((el) => el[1]);
	const maxValue = Math.max.apply(null, listValues);
	//процент, занимаемый колонкой с графиками в таблице
	const maxPercent = 90;
	const valueOnePercent = +(maxPercent / maxValue).toFixed(2);

	const renderItems = dataList.map((item, index) => (
		<StatisticsItem
			dataKey={item[0]}
			dataValue={item[1]}
			key={index}
			valueOnePercent={valueOnePercent}
		/>
	));

	//Добавим строчку "Всего"
	const totalZakazes = listValues.reduce((acc, val) => acc + val);
	//Дополнительные данные для итога (для заказов показываем еще кол-во фото)
	const extraValue = totalFiles ? `(${totalFiles} фото)` : null;

	renderItems.unshift(
		<StatisticsItem
			dataKey={'Всего'}
			dataValue={totalZakazes}
			valueOnePercent={valueOnePercent}
			isTotal={true}
			extraValue={extraValue}
			key={Date.now()}
		/>
	);

	return (
		<table className={s.statBox}>
			<tbody>{renderItems}</tbody>
		</table>
	);
};

export const StatisticsItem = ({
	dataKey,
	dataValue,
	valueOnePercent,
	isTotal = false,
	extraValue = null,
}) => {
	const [barWidth, setBarWidth] = useState(0);
	//for animation rise bar
	setTimeout(() => {
		setBarWidth(valueOnePercent * dataValue);
	}, 10);

	return (
		<tr className={s.statItem + ' ' + (isTotal && s.totalItem)}>
			<td className={s.statKey}>{dataKey}</td>
			<td className={s.statValue}>{dataValue}</td>
			<td className={s.statBarCell}>
				{isTotal ? (
					<div className={s.statExtra}>{extraValue}</div>
				) : (
					<div className={s.statBar} style={{ width: barWidth + '%' }}></div>
				)}
			</td>
		</tr>
	);
};
