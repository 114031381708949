import { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PreviewReklama from './PreviewReklama';
import ReklamaService from '../../services/ReklamaService';
import styles from './reklama.module.css';
import data from './reklamaState';
// Import the Slate editor factory.

const AddReklama = () => {
	const navigate = useNavigate();

	// const [title, setTitle] = useState('');
	const [description, setDescription] = useState(
		"11-14 октября 2022 в Москве, Крокус Экспо состоится 21-ая Международная выставка сварочных материалов, оборудования и технологий – Weldex.<br/><br/>Более 100 производителей и поставщиков представят свои лучшие решения в следующих разделах:<br/><br/><li>Оборудование для сварки</li><br/><li>Материалы для сварочных работ</li><br/><li>Оборудование для резки металла</li><br/><li>Инструменты и приспособления для сварочных работ</li><br/><li>Оборудование для контроля качества сварных соединений</li><br/><li>Промышленные роботы при проведении сварочных работ</li><br/><li>и др. </li><br/><br/>Ассоциация выступает отраслевым информационным партнером выставки. Участники ассоциации могут бесплатно посетить выставку по промокоду «amp». Зарегистрируйтесь по <a href='https://weldex.ru/Rus/get-eticket?utm_source=amp&utm_medium=referral&utm_campaign=eticket&promo=amp' target='_blank' style='color: blue'>сылке</a><br/><br/><b>Это пример, удали меня<b>"
	);
	// const [url, setUrl] = useState('');
	// const [photo_url, setPhoto_url] = useState({});
	// const [photo_url_big, setPhoto_url_big] = useState({});
	// const [file_url, setFile_url] = useState([]);
	// const [top, setTop] = useState(false);
	// const [side, setSide] = useState(false);
	// const [toCard, setToCard] = useState(false);
	// const [card_text, setCard_text] = useState('');
	// const [place, setPlace] = useState('');

	const handleForm = (e) => {
		e.preventDefault();

		const dataForm = e.target;
		// console.log(e)

		const title = dataForm[0]?.value;
		const description = dataForm[1]?.value;
		const url = dataForm[2]?.value;
		const preview_url = dataForm[3]?.files;
		const photo_url = dataForm[4]?.files;
		const file_url = dataForm[5]?.files;

		const top_place = dataForm[6]?.value;
		const side_place = dataForm[7]?.value;
		const card_place = dataForm[8]?.value;
		const card_text = dataForm[9]?.value;
		const overlay = dataForm[10]?.value;

		const formData = new FormData();

		formData.append('title', title);
		formData.append('description', description);
		formData.append('url', url);
		formData.append('top_place', top_place);
		formData.append('side_place', side_place);
		formData.append('card_place', card_place);
		formData.append('card_text', card_text);
		formData.append('overlay', overlay);

		Object.values(preview_url).forEach((photo) => {
			formData.append('preview_url', photo);
		});

		Object.values(photo_url).forEach((photo) => {
			formData.append('photo_url', photo);
		});

		Object.values(file_url).forEach((file) => {
			formData.append('file_url', file);
		});
		(async () => {
			const resp = await ReklamaService.addReklama(formData);
			// console.log(resp)

			navigate(`/reklama`);
		})();
		console.log(formData.get('file_url'));
	};

	const [cardValue, setCardValue] = useState(false);

	return (
		<div className={styles.reklamaWrapper}>
			<form className={styles.form} onSubmit={handleForm}>
				<p>
					Заголовок <b>Пустым быть не может!</b>
				</p>
				<input placeholder="Введите заголовок, основной" />

				<div className={styles.href}>
					<Link to={'/reklama/new'} className={styles.newLink}>
						Новая версия редактора
					</Link>
					<a href="https://html5-editor.net/" target="_blank">
						Если нужно, ссылка на HTML-редактор
					</a>
				</div>

				<p>Описание ПОЛНОЕ вместе с тегами</p>
				<textarea
					placeholder="<b>Введите основной текст</b>"
					value={description}
					onChange={(e) => setDescription(e.target?.value)}
				/>

				<p>Основной сайт компании (будет помещен в кнопку)</p>
				<input placeholder="https://yandex.ru/maps/" />

				<p>
					Выберете превью фото, небольшого разрешения{' '}
					<b>Пустым быть не может!</b>
				</p>
				<input type="file" />

				<p>
					Выберете основное фото, разрешение побольше{' '}
					<b>Пустым быть не может!</b>
				</p>
				<input type="file" />

				<p>Выберете до 5 файлов</p>
				<input type="file" multiple id="file-input" />

				<div className={styles.borderedDiv}>
					<p>
						Размещать или нет в <b>верхнем</b> блоке?
					</p>
					<p>Если 0 - не размещать совсем в этом блоке</p>
					<p>Выберете место в очереди от 1 до 12 </p>
					<p>
						<b>Пустым быть не может!</b>
					</p>
					<input type="number" defaultValue="0" min="0" max="12" />
				</div>

				<div className={styles.borderedDiv}>
					<p>
						Размещать или нет в блоке <b>слева</b>?
					</p>
					<p>Если 0 - не размещать совсем в этом блоке</p>
					<p>Выберете место в очереди от 1 до 12</p>
					<p>
						<b>Пустым быть не может!</b>
					</p>
					<input type="number" defaultValue="0" min="0" max="12" />
				</div>

				<div className={styles.borderedDiv}>
					<p>
						Размещать или нет в <b>карточках</b>?
					</p>
					<p>Если 0 - не размещать совсем в этом блоке</p>
					<p>Выберете место в очереди от 1 до 12</p>
					<p>
						<b>Пустым быть не может!</b>
					</p>
					<input type="number" defaultValue="0" min="0" max="12" />
					<p>Текст который будет написан на карточке под Заголовком</p>
					<input placeholder="Введите текст карточки" />
				</div>

				<p>Оверлей (полупрозрачный фон, и текст на нем)</p>
				<input
					placeholder="Введите сайт"
					defaultValue="Спецпредложения для партнеров ассоциации"
				/>

				<button type="submite">Сохранить</button>
			</form>

			<div className={styles.previewReklama}>
				<PreviewReklama description={description} />
			</div>
		</div>
	);
};

export default AddReklama;
