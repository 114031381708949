import React, { useContext, useState } from 'react';
import { DataGrid, ruRU, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { AuthContext } from '../../hoc/AuthProvider';
import { observer } from 'mobx-react-lite';

const CustomDataGrid = ({ rows, columns, ...params }) => {
	const { store } = useContext(AuthContext);
	const [pageSize, setPageSize] = useState(10);
	return (
		<div style={{ height: 650, width: '90%' }}>
			<DataGrid
				localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
				loading={store?.isLoading}
				getRowId={(row) => row._id}
				rows={rows}
				columns={columns}
				rowsPerPageOptions={[10, 20]}
				pagination
				autoHeight
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				disableSelectionOnClick
				components={{ Toolbar: MyFilterToolBar }}
				{...params}
			/>
		</div>
	);
};

export default observer(CustomDataGrid);

export const MyFilterToolBar = () => {
	return (
		<div style={{ padding: '5px', display: 'flex', justifyContent: 'center' }}>
			<GridToolbarQuickFilter debounceMs={500} placeholder="Быстрый поиск" />
		</div>
	);
};
