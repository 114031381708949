import React from 'react';
import s from './usersStat.module.css';

export const StatUsersList = ({ users }) => {
	const usersList = users?.map((user) => {
		const normDate = user.lastVisit
			? new Date(user.lastVisit).toLocaleString()
			: '-';
		return (
			<li key={user._id} className={s.userStatItem}>
				<span className={s.itemOrg}>{user.org}</span>
				<span className={s.itemName}>{user.name}</span>
				<span className={s.itemEmail}>{user.email}</span>
				<span className={s.itemLastVisit}>{normDate}</span>
			</li>
		);
	});

	return (
		<ul className={s.usersList}>
			<li className={s.userStatItem + ' ' + s.listHeader}>
				<span className={s.itemHeader}>Пользователь</span>
				<span className={s.itemLastVisit}>Последний визит</span>
			</li>
			{usersList}
		</ul>
	);
};
